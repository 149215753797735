import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Insurance extends ContentItem {
  public reasonTitle: Elements.RichTextElement
  public reasonText: Elements.RichTextElement
  public reasonLinks: Elements.RichTextElement
  public reasonPicture: Elements.AssetsElement
  public advantagesTextMain: Elements.RichTextElement
  public advantagesTable: Elements.RichTextElement
  public advantagesLinks: Elements.RichTextElement
  public advantagesTextAdditional: Elements.RichTextElement
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'reason_title') {
          return 'reasonTitle'
        }
        if (elementName === 'reason_text') {
          return 'reasonText'
        }
        if (elementName === 'reason_links') {
          return 'reasonLinks'
        }
        if (elementName === 'reason_picture') {
          return 'reasonPicture'
        }
        if (elementName === 'advantages_text_main') {
          return 'advantagesTextMain'
        }
        if (elementName === 'advantages_table') {
          return 'advantagesTable'
        }
        if (elementName === 'advantages_links') {
          return 'advantagesLinks'
        }
        if (elementName === 'advantages_text_additional') {
          return 'advantagesTextAdditional'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
