import { COUNTRY_CODE } from '../../utils/languageUtils'
import { process_browser } from '../../../components/withNoSSR'
import { useComponentDidMount } from '../../utils/hooks/hooks'
import React, { useRef, useState } from 'react'
import TextInput from './TextInput'

let GoogleMapsLoader: any
if (!(typeof window === 'undefined')) {
  GoogleMapsLoader = require('google-maps')

  GoogleMapsLoader.KEY = process.env.GOOGLE_API_KEY
  GoogleMapsLoader.VERSION = '3.43'
  GoogleMapsLoader.LIBRARIES = ['places']
}

let customerPLaceKeyupDebounce: any = null

type Props = {
  label: string
  parentCallback: null | ((a: any) => void)
}
const GooglePlacesSuggest = ({ label, parentCallback = null }: Props) => {
  const [searchSuggestions, setSearchSuggestions] = useState<null | any[]>(null)
  const [inputValue, setInputValue] = useState('')
  const googleInstance = useRef(
    null as null | any // Parameters<Parameters<typeof GoogleMapsLoader['load']>[0]>[0]
  )

  useComponentDidMount(() => {
    // @ts-expect-error
    GoogleMapsLoader.load(google => {
      googleInstance.current = google

      // get position from user navigator if allowed
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          // eslint-disable-next-line prettier/prettier
          // eslint-disable-next-line new-parens
          const googleLatLngPos = new googleInstance.current!.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          )
          // eslint-disable-next-line new-parens
          const geocoder = new googleInstance.current!.maps.Geocoder()

          if (parentCallback) {
            parentCallback({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          }

          geocoder.geocode({ location: googleLatLngPos }, (result: any, status: any) => {
            if (status === 'OK') setInputValue(result[0].formatted_address)
          })

          setSearchSuggestions([])
        })
      }
    })
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (customerPLaceKeyupDebounce) {
      window.clearTimeout(customerPLaceKeyupDebounce)
    }
    setInputValue(value)
    customerPLaceKeyupDebounce = window.setTimeout(() => {
      if (value.length > 0) {
        // eslint-disable-next-line new-parens
        const sessionToken = new googleInstance.current!.maps.places.AutocompleteSessionToken()
        // eslint-disable-next-line new-parens
        const autocompleteService = new googleInstance.current!.maps.places.AutocompleteService()
        const request = {
          input: value,
          componentRestrictions: {
            country: COUNTRY_CODE.toLowerCase(),
          },
          types: ['(cities)'],
          sessionToken: sessionToken,
        }

        // @ts-expect-error
        autocompleteService.getPlacePredictions(request, (results, status) => {
          if (status === 'OK') {
            setSearchSuggestions([...results])
          }
        })
      }
    }, 200)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchSuggestions && searchSuggestions.length > 0 && e.key === 'Enter') {
      getLatLangFromSuggestion(searchSuggestions[0])
    }
    // @ts-expect-error
    if (e.target.value.length === 0) {
      setSearchSuggestions([])
    }
  }

  const getLatLangFromSuggestion = (suggestion: any) => {
    setSearchSuggestions([])
    setInputValue(suggestion.structured_formatting.main_text)

    // eslint-disable-next-line new-parens
    const geocoder = new googleInstance.current!.maps.Geocoder()
    const address =
      suggestion.structured_formatting.main_text +
      ', ' +
      suggestion.structured_formatting.secondary_text

    // @ts-expect-error
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === (googleInstance.current as any)?.maps.GeocoderStatus.OK) {
        if (parentCallback) {
          parentCallback({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        }
        return results[0].geometry.location
      }
    })
  }

  return (
    <div className='position-relative'>
      <TextInput
        name='town'
        label={label}
        handleChange={e => handleChange(e)}
        handleKeyUp={e => handleKeyUp(e)}
        value={inputValue}
      />
      {(searchSuggestions ?? []).length > 0 && (
        <div className='saps-search-suggestions'>
          <div className='saps-search-suggestions__list-wrapper'>
            <ul className='saps-search-suggestions__list saps-mb-0'>
              {searchSuggestions?.map(item => (
                <li className='saps-search-suggestions__li' key={item.id}>
                  <a
                    className='saps-search-suggestions__a'
                    onClick={() => getLatLangFromSuggestion(item)}
                  >
                    {item.description}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

const GooglePlacesSuggestNoSSR = (props: Parameters<typeof GooglePlacesSuggest>[0]) => {
  if (!process_browser) return <div />
  return <GooglePlacesSuggest {...props} />
}

export default GooglePlacesSuggestNoSSR
