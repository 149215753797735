import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class BuyCar extends ContentItem {
  public buyCarTitle: Elements.RichTextElement
  public buyCarText: Elements.RichTextElement
  public buyCarPicture: Elements.AssetsElement
  public stepsTitle: Elements.RichTextElement
  public stepsItems: Elements.RichTextElement
  public recommendationTitle: Elements.RichTextElement
  public helpTitle: Elements.RichTextElement
  public helpText: Elements.RichTextElement
  public helpButton: Elements.RichTextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'buy_car_title') {
          return 'buyCarTitle'
        }
        if (elementName === 'buy_car_text') {
          return 'buyCarText'
        }
        if (elementName === 'buy_car_picture') {
          return 'buyCarPicture'
        }
        if (elementName === 'steps_title') {
          return 'stepsTitle'
        }
        if (elementName === 'steps_items') {
          return 'stepsItems'
        }
        if (elementName === 'recommendation_title') {
          return 'recommendationTitle'
        }
        if (elementName === 'help_title') {
          return 'helpTitle'
        }
        if (elementName === 'help_text') {
          return 'helpText'
        }
        if (elementName === 'help_button') {
          return 'helpButton'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
