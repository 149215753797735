import { Tooltip } from 'react-tooltip'
import { capitalize } from '../utils'
import { getTranslation } from '../utils/languageUtils'
import React from 'react'

type BodyType = {
  carBodyGroupName: string
  includedBodyTypes?: { id: string }[] | null
  icon: any
}

type Props = {
  bodyTypesList: BodyType[]
  updateBodyTypes: (carBodyGroupName: string, isChecked: boolean) => void
  selectedCarBodyTypeGroupNames: string[]
}

const BasicFilterBodyTypeGroups = (props: Props) => (
  <>
    <label className='saps-label saps-color-grey-200 saps-mb-5'>
      {getTranslation('basic.filter.dropdown.bodyType.label')}
    </label>
    <div className='d-flex justify-content-between'>
      {props.bodyTypesList.map((item, index) => {
        const carorseryText = getTranslation(
          // @ts-expect-error
          'bodyTypes.' + item.carBodyGroupName
        )

        return (
          <div className='saps-input-select-label-wrapper' key={`bodyTypeGroup-${index}-key`}>
            <Tooltip place='top' anchorSelect={`.my-anchor-element-${item.carBodyGroupName}`}>
              <div>{carorseryText && capitalize(carorseryText)}</div>
            </Tooltip>

            <a
              className={`my-anchor-element-${item.carBodyGroupName}`}
              data-html
              data-event='click'
            >
              <label className={`saps-input-select-label `}>
                <input
                  className={`saps-input-select-icon-checkbox `}
                  name={item.carBodyGroupName}
                  type='checkbox'
                  checked={props.selectedCarBodyTypeGroupNames.includes(item.carBodyGroupName)}
                  onChange={e => {
                    const isChecked = e.target.checked
                    props.updateBodyTypes(item.carBodyGroupName, isChecked)
                  }}
                />
                <item.icon />
              </label>
            </a>
          </div>
        )
      })}
    </div>
  </>
)

export default BasicFilterBodyTypeGroups
