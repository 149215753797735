import { ResultItemType } from './HeaderSearch'
import React from 'react'

type Props = {
  item: ResultItemType
  category: string
  onLinkClick: (linkUrl: any) => void
  onSuggestionsClick: (suggestion: string) => void
  getTextSuggestion: (suggestion: string) => React.ReactNode
}

const getHighlightedSuggestion = (suggestion: string) =>
  suggestion
    .replace(/\[match\]/g, '<span class="saps-color-text">')
    .replace(/\[\/match\]/g, '</span>')

export const ResultItem = (props: Props) => {
  return (
    <span>
      {
        // result is a link
        props.category !== 'suggestions' ? (
          <a
            href={props.item.linkUrl}
            onClick={() => props.onLinkClick(props.item.linkUrl)}
            className='saps-header-search__item-a'
            dangerouslySetInnerHTML={{ __html: getHighlightedSuggestion(props.item.suggestion) }}
          />
        ) : (
          // result is a suggestion
          <a
            className='saps-header-search__item-a'
            onClick={() => props.onSuggestionsClick(props.item.suggestion)}
          >
            {props.getTextSuggestion(props.item.suggestion)}
          </a>
        )
      }
    </span>
  )
}
