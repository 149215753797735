import React from 'react'

type Props = {
  isValid?: boolean
  name: string
  label?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  customCSSClass?: string
  value: string
  validation?: string[]
  type?: string
  required?: boolean
}

const TextInput = (props: Props) => {
  const customCSSClass = props.customCSSClass ?? ''
  const required = props.required ?? false

  let dataState = ''

  if (props.value || required) {
    if (props.isValid && props.value === '') {
      dataState = 'valid-empty'
    } else if (props.isValid) {
      dataState = 'valid'
    } else if (props.value && props.value.length > 0) {
      dataState = 'invalid'
    } else {
      dataState = 'invalid-empty'
    }
  }

  return (
    <label className={`saps-input-jsvalidated ${customCSSClass}`}>
      <input
        className='saps-input-jsvalidated__input'
        name={props.name}
        type={props.type ?? 'text'}
        value={props.value}
        onChange={props.handleChange}
        required={required}
        data-state={dataState}
      />
      {props.label && (
        <span className='saps-input-jsvalidated__label'>
          {props.label}
          {required && '*'}
        </span>
      )}
    </label>
  )
}

export default TextInput
