import { SPLink } from '../../components/SPLink'
import { getTranslation } from '../../splus-lib/utils/languageUtils'
import { urls } from '../../urls'
import { withNoSSR } from '../../components/withNoSSR'

import React, { ComponentType, useEffect, useState } from 'react'

type Props = {
  mid: string
}
const TopMenu = withNoSSR(({ mid }: Props) => {
  const getStandalone = () => {
    const url = window.location.href
    const standaloneUrlIndex = url.indexOf('standalone')

    return standaloneUrlIndex !== -1
  }

  const standalone = getStandalone()

  return (
    <React.Fragment>
      <ul className='saps-top-menu' data-component='header-navigation'>
        {mid === '' && (
          <React.Fragment>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.about()}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.about')}
              </SPLink>
            </li>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.buyingVehicle()}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.buying')}
              </SPLink>
            </li>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.sellingVehicle()}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.selling')}
              </SPLink>
            </li>
            <li className='saps-top-menu__item saps-top-menu__item--active'>
              <SPLink
                href={urls.branches()}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.branches')}
              </SPLink>
            </li>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.financing()}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.financing')}
              </SPLink>
            </li>
          </React.Fragment>
        )}
        {mid !== '' && !standalone && (
          <React.Fragment>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.sellingVehicle(mid)}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.selling')}
              </SPLink>
            </li>
            <li className='saps-top-menu__item'>
              <SPLink
                href={urls.branches(mid)}
                className='saps-top-menu__item-a'
                data-component='header-navigation'
                data-item='header-navigation-link'
              >
                {getTranslation('header.top.menu.branches')}
              </SPLink>
            </li>
          </React.Fragment>
        )}
      </ul>
    </React.Fragment>
  )
})

export default TopMenu
