// @ts-expect-error
import * as MarkerClusterer from '@google/markerclusterer'
import { COUNTRY_CODE, LANGUAGE, getTranslation } from '../utils/languageUtils'
import {
  DealerMap_DealerFilterQueryQuery,
  DealerMap_DealersQueryQuery,
  DealerMap_DealersQueryQueryVariables,
} from './DealerMap.__generated__'
import { ReactRelayContext, fetchQuery, graphql } from 'react-relay'
import { gtmDataLayerPush, gtmOldFormDataLayerPush } from '../utils/googleTagManager/utils'
import { sortGpsListByDistance } from '../utils'
import { urls } from '../../urls'
import { useComponentDidMount } from '../utils/hooks/hooks'
import { withNoSSR } from '../../components/withNoSSR'
import DealerMapBranchesList from './DealerMapBranchesList'
import React, { useContext, useEffect, useRef, useState } from 'react'

let GoogleMapsLoader: any

if (!(typeof window === 'undefined')) {
  GoogleMapsLoader = require('google-maps')
}

type Props = {
  viewMode?: string
  onDealerSelect?: ((a: any, b: any, c: any) => any) | null
  onDealersLoad?: ((...args: any[]) => any) | null
  openInNewTab?: boolean
  purchasingCars?: boolean | null
}

const DealerMap = ({
  viewMode = 'default',
  onDealerSelect = null,
  onDealersLoad = null,
  openInNewTab = false,
  purchasingCars = null,
}: Props) => {
  const { environment } = useContext(ReactRelayContext)!
  const googleMapElementRef = useRef(null)
  const [markerDataIsReady, setMarkerDataIsReady] = useState(false)
  const [mapIsReady, setMapIsReady] = useState(false)
  const [mapAndMarkerDataAreReady, setMapAndMarkerDataAreReady] = useState(false)
  const [dealerListFiltered, setDealerListFiltered] = useState(
    [] as DealerMap_DealersQueryQuery['dealers']
  )
  const dealerListRef = useRef([] as any[])
  const googleInstance = useRef<any>(null)
  const googleMapInstance = useRef<any>(null)
  const dealerMapInputRef = useRef<any>(null)
  const googleMapMarkersRef = useRef([] as any[])
  const googleMarkerClustererRef = useRef<any>(null)
  const initialSuggestionsState = { places: [] as any[], dealers: [] as any[] }
  const [searchSuggestions, setSearchSuggestions] = useState(initialSuggestionsState)
  const minLengthOfQueryToSearchSuggest = 2
  const maxLengthOfDealersSuggested = 9999
  let searchKeyPressTimeout: any = null
  const dealersAreFilteredBySearch = useRef(false)

  const lat = LANGUAGE === 'CS' ? 49.778481 : 48.7884315
  const lng = LANGUAGE === 'CS' ? 15.453126 : 19.5422184
  const mapDefaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: 7,
    placeResultZoom: 10,
  }

  useComponentDidMount(async () => {
    // set GoogleMap properties
    GoogleMapsLoader.KEY = process.env.GOOGLE_API_KEY
    GoogleMapsLoader.VERSION = '3.39'
    GoogleMapsLoader.LIBRARIES = ['places', 'geometry']

    const getDealersQuery = graphql`
      query DealerMap_DealersQuery(
        $purchasingCars: Boolean
        $filter: CarFilterInput
        $includeUnused: Boolean!
      ) {
        dealers(
          includeUnused: $includeUnused
          purchasingCars: $purchasingCars
          filter: $filter
          includeMothers: true
        ) {
          id
          isMother
          parentId
          name
          email
          gps {
            latitude
            longitude
          }
          address {
            street
            city
            zip
            region
          }
          carsCount
        }
      }
    `

    const gqlDealerFilterQuery = graphql`
      query DealerMap_dealerFilterQuery($filter: CarFilterInput) {
        dealers(includeUnused: true, includeMothers: true, filter: $filter) {
          id
          isMother
          parentId
        }
      }
    `

    let dealersParam = null
    const mid = new URLSearchParams(window.location.search).get('mid')
    const variables = { filter: { regionCountry: COUNTRY_CODE } }
    const dealersListFilterQueryResponse = await fetchQuery<{
      variables: any
      response: DealerMap_DealerFilterQueryQuery
    }>(environment, gqlDealerFilterQuery, variables).toPromise()

    if (mid !== null) {
      const myDealer = dealersListFilterQueryResponse?.dealers?.find(dealer => dealer.id === mid)
      if (myDealer !== undefined) {
        dealersParam = dealersListFilterQueryResponse?.dealers
          ?.filter(dealer => {
            return myDealer.id === dealer.parentId
          })
          .map(dealer => dealer.id)
      }
    }

    const data = await fetchQuery<{
      variables: DealerMap_DealersQueryQueryVariables
      response: DealerMap_DealersQueryQuery
    }>(environment, getDealersQuery, {
      purchasingCars,
      filter: {
        dealers: dealersParam,
        regionCountry: COUNTRY_CODE,
      },
      includeUnused: dealersParam === null,
    }).toPromise()

    try {
      if (data?.dealers === null) {
        return
      }
      let filteredOutDealers = data?.dealers
      if (mid !== null) {
        if (dealersListFilterQueryResponse !== null) {
          let count = 0
          const myDealer = dealersListFilterQueryResponse?.dealers?.find(
            dealer => dealer.id === mid
          )
          if (myDealer !== undefined) {
            filteredOutDealers = data?.dealers?.filter(d => {
              if (d.parentId === myDealer.id) {
                count += d.carsCount
                return true
              }

              return false
            })
          }
          filteredOutDealers = filteredOutDealers?.map(d => {
            if (d.isMother) {
              return {
                ...d,
                carsCount: count,
              }
            } else {
              return d
            }
          })
          filteredOutDealers = putMotherFirst(filteredOutDealers)
        }
      } else {
        if (dealersListFilterQueryResponse !== null) {
          const mothers = dealersListFilterQueryResponse?.dealers?.filter(d => {
            return d.isMother
          })
          const counts = [] as any[]
          mothers?.forEach(m => {
            let count = 0
            data?.dealers?.forEach(d => {
              if (d.parentId === m.id) {
                count += d.carsCount
              }
            })
            counts.push({
              id: m.id,
              count: count,
            })
          })
          filteredOutDealers = data?.dealers?.map(d => {
            const isNewCount = counts.find(c => c.id === d.id)
            if (isNewCount !== undefined) {
              return {
                ...d,
                carsCount: isNewCount.count,
              }
            } else {
              return d
            }
          })

          filteredOutDealers = putMotherFirst(filteredOutDealers)
        }
      }

      // TODO: uncomment return to make map works on local machine
      // the map has bad api key and react app stops to work at all on your local PC
      //  > fix it somehow with new api key i guess <
      // return null

      // @ts-expect-error
      dealerListRef.current = filteredOutDealers
      setMarkerDataIsReady(true)

      if (onDealersLoad) {
        onDealersLoad(filteredOutDealers)
      }

      // @ts-expect-error
      dealerListRef.current = filteredOutDealers
      if (googleMapInstance.current) {
        filterDealers()
      } else {
        // @ts-expect-error
        setDealerListFiltered([...filteredOutDealers])
      }
    } catch (err) {
      console.error(err)
    }
  })

  useEffect(() => {
    GoogleMapsLoader.load(
      // @ts-expect-error
      google => {
        googleInstance.current = google
        googleMapInstance.current = new googleInstance.current.maps.Map(
          googleMapElementRef.current,
          {
            center: mapDefaultProps.center,
            zoom: mapDefaultProps.zoom,
          }
        )

        setMapIsReady(true)

        const mid = new URLSearchParams(window.location.search).get('mid')

        if (navigator.geolocation && mid === null) {
          navigator.geolocation.getCurrentPosition(position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }

            googleMapInstance.current.panTo(pos)
            googleMapInstance.current.setZoom(mapDefaultProps.placeResultZoom)
          })
        }

        googleMapInstance.current.addListener('dragend', () => {
          filterDealers()
        })

        googleMapInstance.current.addListener('zoom_changed', () => {
          filterDealers()
        })
      }
    )
  }, [googleMapElementRef])

  useEffect(() => {
    if (mapIsReady && markerDataIsReady) {
      setMapAndMarkerDataAreReady(true)
    }
  }, [markerDataIsReady, mapIsReady])

  useEffect(() => {
    if (mapAndMarkerDataAreReady) {
      filterDealers()
    }
  }, [mapAndMarkerDataAreReady])

  useEffect(() => {
    generateMarkers()
  }, [dealerListFiltered])

  const putMotherFirst = (originalArray: any[] | undefined) => {
    const newArray = [] as any[]
    originalArray?.forEach(d => {
      if (d.isMother === true) {
        newArray.push(d)
      }
    })

    originalArray?.forEach(d => {
      if (!d.isMother) {
        newArray.push(d)
      }
    })

    return newArray
  }
  const filterDealers = () => {
    if (!dealersAreFilteredBySearch.current) {
      const mapBounds = googleMapInstance.current.getBounds()?.toJSON()
      const mapCenter = googleMapInstance.current.getCenter()
      let filteredDealers = [] as any[]

      if ((dealerListRef?.current ?? []).length > 0) {
        dealerListRef.current.forEach(item => {
          if (mapBounds) {
            if (
              item.gps.latitude <= mapBounds.north &&
              item.gps.latitude >= mapBounds.south &&
              item.gps.longitude <= mapBounds.east &&
              item.gps.longitude >= mapBounds.west
            ) {
              filteredDealers.push(item)
            }
          } else {
            filteredDealers = dealerListRef.current
          }
        })
      }

      setDealerListFiltered(
        sortGpsListByDistance(filteredDealers, mapCenter, googleInstance.current)
      )
    }
  }

  const generateMarkers = () => {
    const urlSearchQuery = new URLSearchParams(window.location.search)
    let midParam = ''
    if (urlSearchQuery.get('mid') !== null) {
      midParam = '&mid=' + urlSearchQuery.get('mid')
    }
    for (let i = 0; i < googleMapMarkersRef.current.length; i++) {
      googleMapMarkersRef.current[i].setMap(null)
    }
    googleMapMarkersRef.current = []
    let marker

    if (dealerListFiltered && dealerListFiltered.length && googleInstance.current) {
      for (const i in dealerListFiltered) {
        if (dealerListFiltered[i] && dealerListFiltered[i].gps) {
          marker = new googleInstance.current.maps.Marker({
            position: {
              lat: parseFloat(dealerListFiltered[i].gps?.latitude ?? '0'),
              lng: parseFloat(dealerListFiltered[i].gps?.longitude ?? '0'),
            },
            map: googleMapInstance.current,
            title: dealerListFiltered[i].name,
          })
          marker.addListener('click', () => {
            const mid = 'mid=' + dealerListFiltered[i].id + '&dealer=' + dealerListFiltered[i].id
            const url = window.location.origin + '/dealer/' + dealerListFiltered[i].id + '?' + mid
            window.open(url, '_blank', undefined)
          })

          googleMapMarkersRef.current.push(marker)
        }
      }

      if (googleMapInstance.current) {
        if (googleMarkerClustererRef.current) {
          googleMarkerClustererRef.current.clearMarkers()
        }
        googleMarkerClustererRef.current = new MarkerClusterer(
          googleMapInstance.current,
          googleMapMarkersRef.current,
          {
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          }
        )
      }
    }
  }

  const suggest = () => {
    // google places suggestions
    const suggestions = searchSuggestions
    const sessionToken = new googleInstance.current.maps.places.AutocompleteSessionToken()
    const autocompleteService = new googleInstance.current.maps.places.AutocompleteService()
    const request = {
      input: dealerMapInputRef.current.value,
      componentRestrictions: {
        country: COUNTRY_CODE.toLowerCase(),
      },
      types: ['(cities)'],
      sessionToken: sessionToken,
    }

    autocompleteService.getPlacePredictions(request, (results: any, status: any) => {
      if (status === 'OK') {
        suggestions.places = results
        setSearchSuggestions({ ...suggestions })
      }
    })

    suggestDealers()
  }

  const handleInputKeyup = (e: any) => {
    const value = e.target.value
    const key = e.key.toLowerCase()
    dealersAreFilteredBySearch.current = false

    if (key === 'escape') {
      dealerMapInputRef.current.value = ''
      setSearchSuggestions(initialSuggestionsState)
      setDealerListFiltered([...dealerListRef.current])
      return
    }

    if (key === 'enter') {
      submitDealersSearch()
      return
    }

    if (value.length >= minLengthOfQueryToSearchSuggest) {
      if (searchKeyPressTimeout) {
        window.clearTimeout(searchKeyPressTimeout)
      }
      searchKeyPressTimeout = window.setTimeout(() => {
        suggest()
      }, 200)
    } else {
      setSearchSuggestions(initialSuggestionsState)
    }
  }

  const getPlaceFromSuggestion = (suggestion: any) => {
    setSearchSuggestions(initialSuggestionsState)
    dealerMapInputRef.current.value = suggestion.structured_formatting.main_text

    const placesService = new googleInstance.current.maps.places.PlacesService(
      googleMapInstance.current
    )
    const request = {
      placeId: suggestion.place_id,
    }

    placesService.getDetails(request, (result: any, status: any) => {
      if (status === 'OK' && result && result.geometry && result.geometry.location) {
        googleMapInstance.current.panTo(result.geometry.location)
        googleMapInstance.current.setZoom(mapDefaultProps.placeResultZoom)
      }
    })
  }

  const suggestDealers = () => {
    const value = dealerMapInputRef.current.value
    const dealersFilteredBySearch = [] as any[]
    const suggestions = searchSuggestions

    if (dealerListRef.current && dealerListRef.current.length) {
      dealerListRef.current.forEach(dealer => {
        // suggest max [maxLengthOfDealersSuggested=5] dealers
        if (dealersFilteredBySearch.length >= maxLengthOfDealersSuggested) {
          suggestions.dealers = putMotherFirst(dealersFilteredBySearch)

          setSearchSuggestions({ ...suggestions })
          return
        }
        if (dealer.name.toLowerCase().includes(value.toLowerCase())) {
          dealersFilteredBySearch.push(dealer)
        } else if (
          dealer.address.street &&
          dealer.address.street.toLowerCase().includes(value.toLowerCase())
        ) {
          dealersFilteredBySearch.push(dealer)
        } else if (
          dealer.address.city &&
          dealer.address.city.toLowerCase().includes(value.toLowerCase())
        ) {
          dealersFilteredBySearch.push(dealer)
        }
      })
      suggestions.dealers = putMotherFirst(dealersFilteredBySearch)

      setSearchSuggestions({ ...suggestions })
    }
  }

  const submitDealersSearch = () => {
    if (searchSuggestions.places.length > 0) {
      getPlaceFromSuggestion(searchSuggestions.places[0])
      sendGTMData()

      setSearchSuggestions(initialSuggestionsState)
    }
  }

  const sendGTMData = () => {
    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Form success',
      eventLabel: dealerMapInputRef.current.value,
      'appweb.Name': 'ms_form_success',
    })

    gtmOldFormDataLayerPush({
      event: 'formdata',
      eventName: 'dealerMapSearch',
      eventType: 'search',
      formData: {
        search: dealerMapInputRef.current.value,
      },
    })

    // gtmDataLayerPush({
    //   event: 'search_branches',
    //   eCat: 'city',
    //   eAction: searchSuggestions.places[0],
    // })

    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Search branches',
      eventLabel: searchSuggestions.places[0],
      'appweb.Name': 'ms_search_branches',
    })
  }

  const submitPlace = (place: any) => {
    getPlaceFromSuggestion(place)

    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Search branches',
      eventLabel: place.structured_formatting.main_text,
      'appweb.Name': 'ms_search_branches',
    })
    // gtmDataLayerPush({
    //   event: 'search_branches',
    //   eCat: 'city',
    //   eAction: place.structured_formatting.main_text,
    // })
  }

  const submitDealer = (dealerId: any) => {
    const urlSearchQuery = new URLSearchParams(window.location.search)
    let midParam = ''
    if (urlSearchQuery.get('mid') !== null) {
      midParam = urlSearchQuery.get('mid') + ''
    } else {
      midParam = dealerId
    }
    const mid = 'mid=' + midParam
    const url = window.location.origin + urls.dealerDetail(dealerId, mid)

    const dealer = searchSuggestions.dealers.find(d => d.id === dealerId)

    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Search branches',
      eventLabel: dealer?.name,
      'appweb.Name': 'ms_search_branches',
    })

    // gtmDataLayerPush({
    //   event: 'search_branches',
    //   eCat: 'dealer',
    //   eAction: dealerId,
    // })

    window.open(url, '_blank', undefined)
  }

  return (
    <div>
      <div
        className={
          viewMode === 'default' ? 'container saps-mb-30 saps-mb-70-md' : 'saps-mb-30 saps-mb-70-md'
        }
      >
        <div className='row align-items-center'>
          <div className='col-12 col-md-8 saps-mb-20 saps-mb-0-md'>
            <div
              className='position-relative'
              data-component='dealer-map-search'
              data-type='search'
            >
              <p className='saps-p d-md-none saps-mb-10 text-center'>
                {getTranslation('dealerMap.enterDealerName')}
              </p>
              <input
                type='text'
                name='dealerSearchQuery'
                className='saps-dealer-map-input saps-dealer-map-input--map-search'
                ref={dealerMapInputRef}
                placeholder={getTranslation('dealerMap.enterDealerName.loading')}
                onKeyUp={handleInputKeyup}
              />
              <div className='saps-dealer-map-search-suggestions'>
                {(searchSuggestions.places ?? []).length > 0 && (
                  <div className='saps-dealer-map-search-suggestions__list-wrapper'>
                    <h4 className='saps-p saps-bold saps-color-secondary saps-mb-10'>
                      {getTranslation('dealerMap.places')}
                    </h4>
                    <ul className='saps-dealer-map-search-suggestions__list saps-dealer-map-search-suggestions__list--places saps-mb-0'>
                      {searchSuggestions.places.map((place, index) => (
                        <li
                          className='saps-dealer-map-search-suggestions__li'
                          key={`mapPlaceSugestion-${index}`}
                        >
                          <a
                            className='saps-dealer-map-search-suggestions__a saps-a'
                            onClick={() => submitPlace(place)}
                          >
                            {place.structured_formatting.main_text}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {(searchSuggestions.dealers ?? []).length > 0 && (
                  <div className='saps-dealer-map-search-suggestions__list-wrapper'>
                    <h4 className='saps-p saps-bold saps-color-secondary saps-mb-10'>
                      {getTranslation('dealerMap.dealersHeader')}
                    </h4>
                    <ul className='saps-dealer-map-search-suggestions__list saps-dealer-map-search-suggestions__list--places saps-mb-0'>
                      {searchSuggestions.dealers.map((dealer, index) => (
                        <li
                          className='saps-dealer-map-search-suggestions__li'
                          key={`mapDealerSugestion-${index}`}
                        >
                          <a
                            {...(openInNewTab ? { target: '_blank' } : {})}
                            onClick={() => submitDealer(dealer.id)}
                            className='saps-dealer-map-search-suggestions__a saps-a'
                          >
                            {dealer.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-12 col-md-3 col-xl-2'>
            <button
              type='button'
              className='saps-button saps-button--secondary saps-button--fullwidth'
              onClick={submitDealersSearch}
            >
              {getTranslation('dealerMap.searchButton')}
            </button>
          </div>
        </div>
      </div>
      <div
        ref={googleMapElementRef}
        className='saps-dealer-map saps-mb-50'
        style={{ height: '600px', width: '100%' }}
      />
      <DealerMapBranchesList
        dealersList={dealerListFiltered}
        viewMode={viewMode as any}
        onDealerSelect={onDealerSelect}
      />
    </div>
  )
}

export const DealerMapNoSSR = withNoSSR(DealerMap)
