import { findNumberInString } from '../'
import { gtmDataLayerPush, gtmOldFormDataLayerPush } from './utils'
import { notNullable } from '../typeGuards'

const mapParamsToAllFetchedFormValues = {
  make: 'carMakes',
  model: 'carModels',
  colors: 'colors',
  transmissionTypes: 'transmissions',
  fuelTypes: 'fuelTypes',
  bodyTypes: 'bodyTypes',
  dealers: 'dealers',
  equipmentItems: 'equipmentItems',
  regions: 'regionsList',
}

export const sendGTMDataWithNiceFormat = (
  params: Record<string, any>,
  allFetchedFormValues: Record<string, any>
) => {
  if (!params || !allFetchedFormValues) {
    return
  }

  const gtmFormData = {
    event: 'trackEvent',
    eventCategory: 'Microsite - skodaplus',
    eventAction: 'Filtr',
    // TODO: is this proper format
    eventLabel: {},
    'appweb.Name': 'ms_filtr',

    // change the structure of GTM
    // this is an old version
    // event: 'formdata',
    // eventName: 'filter',
    // eventType: eventType,
    // formData: {},
  }
  if (params) {
    for (const paramKey in params) {
      const paramValue = params[paramKey]
      if (Array.isArray(paramValue)) {
        // @ts-expect-error
        gtmFormData.eventLabel[paramKey] = paramValue
          .map(id => {
            // @ts-expect-error
            const mappedKeyName = mapParamsToAllFetchedFormValues[paramKey]
            const paramAvailableValues = allFetchedFormValues[mappedKeyName]
            if (!paramAvailableValues) {
              return null
            }
            // @ts-expect-error
            return paramAvailableValues.find(availableValue => availableValue.id === id)
          })
          .filter(notNullable)
          .map(foundValue => `${findNumberInString(foundValue.id)}:${foundValue.name}`)
      } else {
        // @ts-expect-error
        gtmFormData.eventLabel[paramKey] = paramValue
      }
    }
  }

  gtmDataLayerPush(gtmFormData)

  gtmOldFormDataLayerPush({
    event: 'formdata',
    eventName: 'filter',
    eventType: 'search',
    formData: gtmFormData.eventLabel,
  })

  if (params.mileageRange && params.mileageRange?.min !== null && params.mileageRange.min > 10000) {
    gtmDataLayerPush({
      event: 'remarketing_trigger',
      eCat: 'mileage_100000',
    })
  }

  if (params.firstRegistration && params.firstRegistration.max !== null) {
    if (params.firstRegistration.max < 2015) {
      gtmDataLayerPush({
        event: 'remarketing_trigger',
        eCat: 'year_2015',
      })
    }
  }
}
