import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Configuration extends ContentItem {
  public context: Elements.TextElement
  public odkazNaNastaveniCookies: Elements.TextElement
  public carDetailUrl: Elements.TextElement
  public unsubscribeUrl: Elements.TextElement
  public objectType: Elements.TaxonomyElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'odkaz_na_nastaveni_cookies') {
          return 'odkazNaNastaveniCookies'
        }
        if (elementName === 'car_detail_url') {
          return 'carDetailUrl'
        }
        if (elementName === 'unsubscribe_url') {
          return 'unsubscribeUrl'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        return elementName
      },
    })
  }
}
