import { ValidationOption } from '../../utils/api/apiUtils'
import { validateInput } from '../../utils'
import React, { useState } from 'react'

type Props = {
  name: string
  label?: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  customCSSClass?: string
  type?: string
  validation?: ValidationOption[]
  value: string
  required?: boolean
}

const TextInput = (props: Props) => {
  const [isValid, setIsValid] = useState(true)

  return (
    <label
      className={`saps-input-basic ${props.customCSSClass ?? ''} ${
        isValid ? '' : ' saps-input-basic--invalid'
      }`}
    >
      <input
        className='saps-input-basic__input'
        name={props.name}
        type={props.type ?? 'text'}
        value={props.value}
        onChange={props.handleChange}
        onKeyUp={props.handleKeyUp}
        onBlur={() => setIsValid(validateInput(props.value, props.validation ?? []))}
        onFocus={() => setIsValid(true)}
        required
      />
      {props.label && <span className='saps-input-basic__label'>{props.label}</span>}
    </label>
  )
}

export default React.memo(TextInput)
