import { ReactComponent as Play } from './svg/play.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getTranslation } from '../../utils/languageUtils'
import React, { useEffect, useRef, useState } from 'react'
import VideoLightbox from './VideoLightbox'
import markdownToHtml from '../../../../lib/markdownToHtml'
import styled from 'styled-components'

const DivTitle = styled.div`
  h3,
  p {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 1.13;
    }
  }
`

const DivText = styled.div`
  p {
    font-size: 15px;
    line-height: 1.6;
  }
`

const SliderArrow = ({ ...buttonProps }) => (
  <button {...buttonProps}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='40'
      viewBox='0 0 256 512'
      className='saps-cars-carousel__arrow-icon'
    >
      {' '}
      <path
        d='M194.5 419L28 252.5 194.5 86q4.5-4 10.25-4T215 86q4 4 4 10t-4 10L68.5 252.5 215 399q4 4 4 10t-4 10-9.75 4-10.75-4z'
        className='saps-cars-carousel__arrow-icon-path'
      />{' '}
    </svg>
  </button>
)

type Props = {
  images?: string[]
  videoUrl?: string
  title?: string
  text?: string
  parentCategory?: string | null
  handleClose?: () => void
  customCSSClass?: string | null
  smallTitle?: string | null
  smallSize?: boolean
  children?: any
}

const ParamLightbox = ({
  images,
  videoUrl,
  title,
  text,
  parentCategory,
  handleClose,
  children,
  customCSSClass,
  smallTitle,
  smallSize = false,
}: Props) => {
  const [wrapperRef, setWrapperRef] = useState<null | HTMLDivElement>(null)
  const [videoVisible, setVideoVisible] = useState<undefined | boolean>(undefined)
  const gallerySwiper = useRef<any | undefined>(undefined)

  useEffect(() => {
    document.querySelector('body')!.style.overflowY = 'hidden'
    return () => {
      document.querySelector('body')!.style.overflowY = ''
    }
  })

  useEffect(() => {
    if (gallerySwiper.current) {
      gallerySwiper.current.swiper.update()
      const navigation = gallerySwiper.current.swiper.navigation
      if (navigation) {
        navigation.init()
        navigation.update()
      }
    }
  }, [gallerySwiper])

  useEffect(() => {
    const handleClickAnywhere = (e: any) => {
      // close lightbox if clicked outside of it
      // excluded input element, which was causing watchdog lightbox to close even if the input is part of lightbox
      if (
        typeof handleClose === 'function' &&
        wrapperRef &&
        !wrapperRef.contains(e.target) &&
        e.target.nodeName !== 'INPUT'
      ) {
        handleClose()
      }
    }

    const handleEscape = (e: any) => {
      // close lightbox if escape was pressed
      if (typeof handleClose === 'function' && e.keyCode === 27) {
        handleClose()
      }
    }
    document.addEventListener('click', handleClickAnywhere)
    document.addEventListener('keydown', handleEscape)
    return () => {
      document.removeEventListener('click', handleClickAnywhere)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [handleClose])

  return (
    <div className='saps-lightbox-wrapper saps-lightbox-wrapper--active'>
      {children ? (
        <div
          className={`saps-lightbox ${customCSSClass ? customCSSClass : ''}`}
          ref={setWrapperRef}
        >
          <a className='saps-lightbox__close' onClick={handleClose} />
          <div className='saps-lightbox__content-wrapper'>{children}</div>
        </div>
      ) : (
        <div
          className={`saps-lightbox ${
            !images || images.length === 0
              ? smallSize
                ? 'saps-lightbox--no-image-small'
                : 'saps-lightbox--no-image-large'
              : ''
          }`}
          ref={setWrapperRef}
        >
          <a className='saps-lightbox__close' onClick={handleClose} />
          {images && images.length === 1 && (
            <div className='saps-lightbox__image' style={{ backgroundImage: `url(${images[0]})` }}>
              {videoUrl && (
                <div onClick={() => setVideoVisible(true)}>
                  <Play />
                </div>
              )}
            </div>
          )}
          {images && images.length > 1 && (
            <Swiper
              ref={gallerySwiper}
              slideClass={'saps-lightbox-carousel__slide'}
              slidesPerView={1}
              // rebuildOnUpdate={true}
              keyboard={{
                enabled: true,
              }}
              noSwipingClass={'saps-lightbox-carousel_video-button'}
              navigation={{
                nextEl: '.saps-carousel-next.saps-carousel--force-display-arrows',
                prevEl: '.saps-carousel-prev.saps-carousel--force-display-arrows',
              }}
              pagination={{
                el: '.saps-lightbox-carousel__pagination',
                bulletClass: 'saps-lightbox-carousel__pagination-bullet',
                bulletActiveClass: 'saps-lightbox-carousel__pagination-bullet_active',
                clickable: true,
              }}
              // containerClass={'saps-lightbox-carousel'}
              // renderPrevButton={() => (
              //   <SliderArrow className='saps-carousel-prev saps-carousel--force-display-arrows' />
              // )}
              // renderNextButton={() => (
              //   <SliderArrow className='saps-carousel-next saps-carousel--force-display-arrows' />
              // )}
            >
              {images?.map((imageUrl, index) => (
                <SwiperSlide key={JSON.stringify(imageUrl)}>
                  <div
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                  >
                    {index === 0 && videoUrl && (
                      <div onClick={() => setVideoVisible(true)}>
                        <Play className='saps-lightbox-carousel_video-button' />
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className='saps-lightbox__content-wrapper'>
            <div
              className='saps-lightbox__content'
              style={smallSize ? { padding: '100px 80px' } : {}}
            >
              <p className='saps-lightbox__category'>
                {getTranslation(
                  // @ts-expect-error
                  'car.params.' + parentCategory
                )}
              </p>
              {smallTitle && <p className='saps-lightbox__category'>{smallTitle}</p>}
              <DivTitle>{title && markdownToHtml(title)}</DivTitle>
              <DivText>{text && markdownToHtml(text)}</DivText>
            </div>
            <div className='polygon'>
              <svg preserveAspectRatio='none' viewBox='0 0 100 100'>
                <polygon points='100,100 100,0 0,0' opacity='1' />
              </svg>
            </div>
          </div>
          {videoVisible && (
            <VideoLightbox videoUrl={videoUrl!} nested handleClose={() => setVideoVisible(false)} />
          )}
        </div>
      )}
    </div>
  )
}

export default ParamLightbox
