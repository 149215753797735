import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class AlternativeDrives extends ContentItem {
  public title: Elements.RichTextElement
  public description: Elements.RichTextElement
  public image: Elements.AssetsElement
  public alternativeDrivesSection: Elements.RichTextElement
  public recommendationTitle: Elements.RichTextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'alternative_drives_section') {
          return 'alternativeDrivesSection'
        }
        if (elementName === 'recommendation_title') {
          return 'recommendationTitle'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
