import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class SellingCar extends ContentItem {
  public sellCarTitle: Elements.RichTextElement
  public sellCarText: Elements.RichTextElement
  public sellCarPicture: Elements.AssetsElement
  public stepsTitle: Elements.RichTextElement
  public stepsItems: Elements.RichTextElement
  public formTitle: Elements.RichTextElement
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'sell_car_title') {
          return 'sellCarTitle'
        }
        if (elementName === 'sell_car_text') {
          return 'sellCarText'
        }
        if (elementName === 'sell_car_picture') {
          return 'sellCarPicture'
        }
        if (elementName === 'steps_title') {
          return 'stepsTitle'
        }
        if (elementName === 'steps_items') {
          return 'stepsItems'
        }
        if (elementName === 'form_title') {
          return 'formTitle'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
