import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Action extends ContentItem {
  public title: Elements.RichTextElement
  public picture: Elements.AssetsElement
  public subtitle: Elements.RichTextElement
  public leftColumnText: Elements.RichTextElement
  public rightColumnText: Elements.RichTextElement
  public tlacitkoText: Elements.TextElement
  public tlacitkoOdkaz: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'left_column_text') {
          return 'leftColumnText'
        }
        if (elementName === 'right_column_text') {
          return 'rightColumnText'
        }
        if (elementName === 'tlacitko_text') {
          return 'tlacitkoText'
        }
        if (elementName === 'tlacitko_odkaz') {
          return 'tlacitkoOdkaz'
        }
        return elementName
      },
    })
  }
}
