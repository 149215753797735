import { Checkbox } from '../index'
import { FeaturesFilter_DataFragment } from './FeaturesFilter.__generated__'
import { capitalize, sortByNestedName } from '../../utils'
import { createFragmentContainer, graphql } from 'react-relay'
import { getTranslation } from '../../utils/languageUtils'
import React, { useCallback, useMemo } from 'react'

const RenderParam = React.memo(
  ({ equipment, parentCategory, equipmentItems, handleCustomCheckboxChange }: any) => {
    const checked = equipmentItems.indexOf(equipment.id) > -1

    const handleChange = useCallback(
      (e: any) => {
        handleCustomCheckboxChange('equipmentItems', e.target.name, !checked)
      },
      [checked, handleCustomCheckboxChange]
    )

    return (
      <div
        className='saps-car-detail-features__value-wrapper col-xs-12 col-sm-6 col-lg-4'
        key={`car-feature-${parentCategory}-${equipment.id}`}
      >
        <Checkbox
          customCSSClass='saps-car-detail-features__value'
          name={equipment.id}
          text={capitalize(equipment.name)}
          checked={checked}
          handleChange={handleChange}
        />
      </div>
    )
  }
)

type Props = {
  data: FeaturesFilter_DataFragment | null | undefined
  equipmentItems: string[]
  handleCustomCheckboxChange: (a: any, b: any, c: any) => void
}

const FeaturesFilter = (props: Props) => {
  const sortedEquipmentItems = useMemo(
    () => sortByNestedName(props.data?.equipmentItems ?? []),
    [props.data?.equipmentItems]
  )

  const outerEquipment = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_1'),
    [sortedEquipmentItems]
  )
  const innerEquipment = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_2'),
    [sortedEquipmentItems]
  )
  const audio = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_3'),
    [sortedEquipmentItems]
  )
  const engine = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_4'),
    [sortedEquipmentItems]
  )
  const safetyEquipment = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_5'),
    [sortedEquipmentItems]
  )
  const other = useMemo(
    () => sortedEquipmentItems.filter(item => item.category?.id === 'equipment_category_6'),
    [sortedEquipmentItems]
  )

  return (
    <div className='saps-car-detail-features'>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.outerEquipment')}
        </div>
        <div className='row'>
          {outerEquipment.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='outerEquipment'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.innerEquipment')}
        </div>
        <div className='row'>
          {innerEquipment.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='innerEquipment'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.safetyEquipment')}
        </div>
        <div className='row'>
          {safetyEquipment.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='safetyEquipment'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.engine')}
        </div>
        <div className='row'>
          {engine.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='engine'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.audio')}
        </div>
        <div className='row'>
          {audio.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='audio'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className='saps-mb-70'>
        <div className='saps-car-detail-features__title saps-mb-30'>
          {getTranslation('car.params.other')}
        </div>
        <div className='row'>
          {other.map((equipment, index) => (
            <RenderParam
              key={index}
              equipment={equipment}
              parentCategory='other'
              equipmentItems={props.equipmentItems}
              handleCustomCheckboxChange={props.handleCustomCheckboxChange}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default createFragmentContainer(React.memo(FeaturesFilter), {
  data: graphql`
    fragment FeaturesFilter_data on Query @argumentDefinitions(lang: { type: "Lang!" }) {
      equipmentItems {
        id
        name(lang: $lang)
        category {
          id
          name(lang: $lang)
        }
      }
    }
  `,
})
