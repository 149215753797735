import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class UsedCars extends ContentItem {
  public usedCarsTitle: Elements.RichTextElement
  public usedCarsText: Elements.RichTextElement
  public usedCarsPicture: Elements.AssetsElement
  public advantagesTitle: Elements.RichTextElement
  public advantagesItems: Elements.RichTextElement
  public advantagesLinkText: Elements.RichTextElement
  public advantagesLinkUrl: Elements.TextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'used_cars_title') {
          return 'usedCarsTitle'
        }
        if (elementName === 'used_cars_text') {
          return 'usedCarsText'
        }
        if (elementName === 'used_cars_picture') {
          return 'usedCarsPicture'
        }
        if (elementName === 'advantages_title') {
          return 'advantagesTitle'
        }
        if (elementName === 'advantages_items') {
          return 'advantagesItems'
        }
        if (elementName === 'advantages_link_text') {
          return 'advantagesLinkText'
        }
        if (elementName === 'advantages_link_url') {
          return 'advantagesLinkUrl'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
