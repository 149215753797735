import { ScreenBreakPointsEnum } from './enums'
import { process_browser } from '../../components/withNoSSR'
import React, { createContext, useContext, useEffect, useState } from 'react'

const checkWindowMaxWidth = (width: any) => {
  if (process_browser === false) {
    return false
  }
  return window.matchMedia(`(max-width: ${width}px)`).matches
}

// TODO: join these context to one
// for example: { isMobile: boolean, isMedium: boolean }
export const IsMobile = createContext(checkWindowMaxWidth(ScreenBreakPointsEnum.XS))
export const IsMediumDeviceDown = createContext(checkWindowMaxWidth(ScreenBreakPointsEnum.MD))

type Props = {
  children: React.ReactNode
}
export const SaplusResizeProvider = ({ children }: Props) => {
  const [mobile, setMobile] = useState(useContext(IsMobile))
  const [mediumDeviceDown, setMediumDeviceDown] = useState(useContext(IsMediumDeviceDown))

  useEffect(() => {
    if (process_browser === false) {
      return
    }

    const handleResize = () => {
      setMobile(checkWindowMaxWidth(ScreenBreakPointsEnum.XS))
      setMediumDeviceDown(checkWindowMaxWidth(ScreenBreakPointsEnum.MD))
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <IsMediumDeviceDown.Provider value={mediumDeviceDown}>
      <IsMobile.Provider value={mobile}>{children}</IsMobile.Provider>
    </IsMediumDeviceDown.Provider>
  )
}
