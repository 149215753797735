import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class PictureWithTextAndLink extends ContentItem {
  public picture: Elements.AssetsElement
  public text: Elements.RichTextElement
  public linkDescription: Elements.TextElement
  public url: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'link_description') {
          return 'linkDescription'
        }
        return elementName
      },
    })
  }
}
