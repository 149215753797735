import React from 'react'

type Props = {
  name: string
  text?: string
  id?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => any
  customCSSClass?: string
  checked: boolean
  tooltip?: string
}

const Checkbox = React.memo((props: Props) => {
  return (
    <>
      <label className={`saps-checkbox-basic ${props.customCSSClass ?? ''}`}>
        <input
          className='saps-checkbox-basic__input'
          name={props.name}
          type='checkbox'
          checked={props.checked}
          onChange={props.handleChange}
          id={props.id}
        />
        <span className='saps-checkbox-basic__checkbox' />
        <span className='saps-checkbox-basic__text'>{props.text}</span>
      </label>
      <label className='saps-checkbox-basic-tooltip'>
        <span>{props.tooltip}</span>
      </label>
    </>
  )
})

export default Checkbox
