import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class AdvantagesAndFinancing extends ContentItem {
  public advantagesTitle: Elements.RichTextElement
  public advantagesPerex: Elements.RichTextElement
  public advantagesText: Elements.RichTextElement
  public financingTitle: Elements.RichTextElement
  public financingUrl: Elements.TextElement
  public financingPerex: Elements.RichTextElement
  public financingText: Elements.RichTextElement
  public watchdogPicture: Elements.AssetsElement
  public watchdogText: Elements.RichTextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'advantages_title') {
          return 'advantagesTitle'
        }
        if (elementName === 'advantages_perex') {
          return 'advantagesPerex'
        }
        if (elementName === 'advantages_text') {
          return 'advantagesText'
        }
        if (elementName === 'financing_title') {
          return 'financingTitle'
        }
        if (elementName === 'financing_url') {
          return 'financingUrl'
        }
        if (elementName === 'financing_perex') {
          return 'financingPerex'
        }
        if (elementName === 'financing_text') {
          return 'financingText'
        }
        if (elementName === 'watchdog_picture') {
          return 'watchdogPicture'
        }
        if (elementName === 'watchdog_text') {
          return 'watchdogText'
        }
        return elementName
      },
    })
  }
}
