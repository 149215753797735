import { getTranslation } from '../../utils/languageUtils'
import React, { useEffect, useState } from 'react'

type Props = {
  parentValue?: string | null
  setRangeValue: (v: any) => void
  rangeSide: 'min' | 'max'
  units?: string | null
  maxValue: number
  maxLabel?: string | null
  thousandDelimiter?: boolean
}
const InputSliderTextInput = (props: Props) => {
  const [value, setValue] = useState(props.parentValue)
  const [valueBeforeFocus, setValueBeforeFocus] = useState(value)

  const handleInputFocus = () => {
    setValueBeforeFocus(value)
    if (value?.toLowerCase() === props.maxLabel?.toLowerCase() || value === '0') {
      setValue('')
    }
  }

  const handleInputBlur = () => {
    if (value?.match(/[^\d]/g)) {
      setValue(valueBeforeFocus)
      return
    }
    if (value !== valueBeforeFocus && value !== '') {
      const newValue =
        value?.toLowerCase() === props.maxLabel?.toLowerCase() ? props.maxValue : value
      setValueBeforeFocus(newValue as string)
      setValue(
        // @ts-expect-error
        newValue! >= props.maxValue
          ? props.maxLabel ?? getTranslation('general.filter.no-limit')
          : props.thousandDelimiter
            ? parseInt(value ?? '').toLocaleString()
            : value
      )
      props.setRangeValue(parseInt(newValue as string))
    } else {
      setValue(valueBeforeFocus)
    }
  }

  useEffect(() => {
    setValue(props.parentValue)
    setValueBeforeFocus(props.parentValue)
  }, [props.parentValue])

  return (
    <div
      className={`saps-slider-range__border ${
        (props.rangeSide ?? 'min') === 'min' ? 'saps-mr-10' : 'saps-ml-10'
      }`}
    >
      <div className='saps-slider-range__border-input-wrapper'>
        <input
          type='text'
          className={`saps-slider-range__border-input ${
            props.rangeSide === 'max' ? ' saps-slider-range__border-input--right' : ''
          }`}
          value={value ?? ''}
          onChange={e => setValue(e.target.value)}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
        />
      </div>
      <div className='saps-slider-range__units-wrapper'>
        {props.units && <span className='saps-slider-range__units'>{props.units}</span>}
      </div>
    </div>
  )
}

export default React.memo(InputSliderTextInput)
