export const firstLetterCapital = (text: string) =>
  text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()

const recommenderModels = [
  {
    name: 'fabia',
    imageSrc: '/static/models/fabia.png',
  },

  {
    name: 'scala',
    imageSrc: '/static/models/scala.png',
  },
  {
    name: 'octavia',
    imageSrc: '/static/models/octavia.png',
  },
  {
    name: 'enyaq',
    imageSrc: '/static/models/enyaq.png',
  },
  {
    name: 'kamiq',
    imageSrc: '/static/models/kamiq.png',
  },
  {
    name: 'karoq',
    imageSrc: '/static/models/karoq.png',
  },
  {
    name: 'kodiaq',
    imageSrc: '/static/models/kodiaq.png',
  },
  {
    name: 'superb',
    imageSrc: '/static/models/superb.png',
  },
]

type Model = {
  id: string
  modelName: string
  carsCount: number
  carMake: {
    id: string
  }
}

/**
 * magic -> TODO: change backend schema
 *
 * TODO: add documentation
 */
export const getRecommendedModelsByCars = (models: Model[], mainCarMake: string) => {
  let defaultModelId = 10000000000
  const mainCarMakeModels = models.filter(model => model.carMake.id === mainCarMake)
  return recommenderModels
    .map(recommendedModel => {
      let model = mainCarMakeModels.find(
        model => firstLetterCapital(model.modelName) === firstLetterCapital(recommendedModel.name)
      )

      // if showing Rapid, show also Rapid Spaceback
      if (recommendedModel.name === 'rapid') {
        const rapidSpacebackId = 'model_1695'
        const rapidSpaceBackModel = mainCarMakeModels.find(model => model.id === rapidSpacebackId)
        if (model) {
          model = {
            ...model,
            carsCount: model.carsCount + (rapidSpaceBackModel?.carsCount ?? 0),
          }
        }
      }
      return {
        modelName: recommendedModel.name,
        model: model,
        ...recommendedModel,
      }
    })
    .map(({ modelName, model, imageSrc }) => {
      let modelId = defaultModelId as number | string
      if (typeof model !== 'undefined') {
        modelId = model.id
      }
      defaultModelId = defaultModelId + 1
      return {
        id: `${modelId}`,
        name: firstLetterCapital(modelName),
        imageSrc: imageSrc,
        mainCarMake: mainCarMake,
        carsCount: model?.carsCount ?? 0,
      }
    })
}
