import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class ExtendedWarranty extends ContentItem {
  public extendedWarrantyIntroduction: Elements.RichTextElement
  public extendedWarrantyPicture: Elements.AssetsElement
  public extendedWarrantyDescription: Elements.RichTextElement
  public extendedWarrantyTable: Elements.RichTextElement
  public extendedWarrantyTableNote: Elements.RichTextElement
  public extendedWarrantyAdditionalInfo: Elements.RichTextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'extended_warranty_introduction') {
          return 'extendedWarrantyIntroduction'
        }
        if (elementName === 'extended_warranty_picture') {
          return 'extendedWarrantyPicture'
        }
        if (elementName === 'extended_warranty_description') {
          return 'extendedWarrantyDescription'
        }
        if (elementName === 'extended_warranty_table') {
          return 'extendedWarrantyTable'
        }
        if (elementName === 'extended_warranty_table_note') {
          return 'extendedWarrantyTableNote'
        }
        if (elementName === 'extended_warranty_additional_info') {
          return 'extendedWarrantyAdditionalInfo'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
