import React, { useState } from 'react'

const fileTypes = {
  jpeg: 'image/jpeg',
} as const

export type AcceptFileType = keyof typeof fileTypes

type Props = {
  name: string
  text: string
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    fileTypeValidator: (files: FileList | null, accept: AcceptFileType) => boolean
  ) => void
  accept?: AcceptFileType
}

const allFilesValidType = (files: FileList | null, accept: AcceptFileType): boolean => {
  console.log('Accept', accept)
  console.log('Evaluating files', fileTypes[accept])
  if (!files) return true
  return Array.from(files).reduce((acc, file) => {
    if (file.type !== fileTypes[accept]) return false
    return acc
  }, true)
}

const InputFile = (props: Props) => {
  return (
    <label className='saps-label-input-upload saps-mb-0'>
      <input
        type='file'
        className='saps-input-upload'
        name={props.name}
        onChange={e => {
          props.handleChange(e, () =>
            allFilesValidType(e.target.files, props.accept as AcceptFileType)
          )
        }}
        multiple
        accept={props.accept ? fileTypes[`${props.accept}`] : 'image/jpeg'}
      />
      <span className='saps-input-upload__text'>{props.text}</span>
    </label>
  )
}

export default InputFile
