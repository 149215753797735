import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class ProgramsSkodaplus extends ContentItem {
  public picture: Elements.AssetsElement
  public title: Elements.RichTextElement
  public descriptionLeft: Elements.RichTextElement
  public descriptionRight: Elements.RichTextElement
  public link: Elements.RichTextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'description_left') {
          return 'descriptionLeft'
        }
        if (elementName === 'description_right') {
          return 'descriptionRight'
        }
        return elementName
      },
    })
  }
}
