import React from 'react'

type Props = {
  text: string
  name: string
  value: string
  checked: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const InputRadio = (props: Props) => {
  return (
    <label className='saps-label-radio'>
      <input
        type='radio'
        className='saps-input-radio'
        name={props.name}
        value={props.value}
        defaultChecked={props.checked ?? false}
        onChange={e => props.handleChange(e)}
      />
      <span className='saps-input-radio__dot' />
      <span className='saps-input-radio__text'>{props.text}</span>
    </label>
  )
}

export default InputRadio
