import { ReactComponent as IconCross } from './svg/cross.svg'
import { Portal } from '../index'
import { withNoSSR } from '../../../components/withNoSSR'
import React, { LegacyRef, useEffect } from 'react'

const ContentWrapper = ({ children, isMd }: { children: React.ReactChild; isMd?: boolean }) => {
  if (!isMd) {
    return <>{children}</>
  }

  return (
    <div className='saps-modal-md--content' style={{ overflowY: 'scroll' }}>
      {children}
    </div>
  )
}

type Props = {
  handleClose: () => void
  children?: React.ReactChild
  className?: string
  modalVisible?: boolean
  sm?: boolean
  dontHideContent?: boolean
  modalRef?: LegacyRef<HTMLDivElement>
}
const Modal = withNoSSR(
  ({
    children,
    handleClose,
    className,
    modalVisible = false,
    sm = false,
    dontHideContent = false,
    modalRef,
  }: Props) => {
    useEffect(() => {
      if (!dontHideContent) {
        ;(document.querySelector('body') as HTMLElement).style.paddingTop = '0'
        ;(document.querySelector('.saps-page-wrapper') as HTMLElement).style.display = 'none'
        ;(document.querySelector('.saps-header') as HTMLElement).style.display = 'none'
        ;(document.querySelector('.saps-footer') as HTMLElement).style.display = 'none'
      }

      return () => {
        if (!dontHideContent) {
          ;(document.querySelector('body') as HTMLElement).style.paddingTop = ''
          ;(document.querySelector('.saps-page-wrapper') as HTMLElement).style.display = ''
          ;(document.querySelector('.saps-header') as HTMLElement).style.display = ''
          ;(document.querySelector('.saps-footer') as HTMLElement).style.display = ''
          return
        }
      }
    }, [])

    return (
      <Portal id='fullPage'>
        <div
          ref={modalRef ?? null}
          className={`${sm ? 'saps-modal-md' : 'saps-modal-page'} ${className ? className : ''} ${
            modalVisible === true ? 'saps-modal-page_active' : ''
          }`}
        >
          <ContentWrapper isMd={dontHideContent}>
            <>
              {dontHideContent ? (
                <div className='d-flex w-full justify-content-end mr-3'>
                  <a
                    style={{ fontSize: '2.5rem', alignSelf: 'right', cursor: 'pointer' }}
                    onClick={handleClose}
                  >
                    &times;
                  </a>
                </div>
              ) : (
                <a className='saps-modal-page__close' onClick={handleClose}>
                  <IconCross className='color-black' />
                </a>
              )}
              {children}
            </>
          </ContentWrapper>
        </div>
      </Portal>
    )
  }
)

export default Modal
