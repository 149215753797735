import { InputSliderTextInput } from '../index'
import React, { useEffect, useState } from 'react'
import Slider from 'rc-slider'

type Props = {
  minValue: number
  maxValue: number
  minLabel?: string | null
  maxLabel?: string | null
  selectedValueFrom?: number | null
  selectedValueTo?: number | null
  handleRangeChange: (v: [number | undefined | null, number | undefined | null], b?: string) => void
  units?: string | null
  title?: string | null
  name: string
  customClass: string
  customLabelCSSClass?: string | null
  customStep?: number | null
  thousandDelimiter?: boolean
}

const InputSlider = React.memo((props: Props) => {
  // we re-save state to local component because we wont handle
  // slider sub move states from parent component
  const [currRangeMin, setCurrRangeMin] = useState(props.selectedValueFrom)
  const [currRangeMax, setCurrRangeMax] = useState(props.selectedValueTo)

  // values of inputs have to be separated from range state
  // because of maxLabelValue text causing inconsistencies
  const [inputFromValue, setInputFromValue] = useState(props.selectedValueFrom || props.minValue)
  const [inputToValue, setInputToValue] = useState(props.selectedValueTo || props.maxLabel)

  useEffect(() => {
    setCurrRangeMin(props.selectedValueFrom)
    setCurrRangeMax(props.selectedValueTo)
  }, [props.selectedValueFrom, props.selectedValueTo])

  const handleOnAfterChange = ([valueFrom, valueTo]: [number, number]) => {
    const processedValueFrom = valueFrom === props.minValue ? null : valueFrom
    const processedValueTo = valueTo === props.maxValue ? null : valueTo
    props.handleRangeChange([processedValueFrom, processedValueTo], props.name)
  }

  const handleOnChange = ([valueFrom, valueTo]: [number, number]) => {
    const processedValueFrom = valueFrom === props.minValue ? null : valueFrom
    const processedValueTo = valueTo === props.maxValue ? null : valueTo
    setInputFromValue(valueFrom)
    setInputToValue(valueTo === props.maxValue ? props.maxLabel : valueTo.toString())
    setCurrRangeMin(processedValueFrom)
    setCurrRangeMax(processedValueTo)
  }

  const handleInputBlurMin = (value: number) => {
    setCurrRangeMin(value)
    props.handleRangeChange([value, currRangeMax], props.name)
  }

  const handleInputBlurMax = (value: number) => {
    setCurrRangeMax(value)
    props.handleRangeChange([currRangeMin, value], props.name)
  }

  const step = props.customStep ? props.customStep : props.maxValue / 20

  return (
    <div className={`saps-input-slider ${props.customClass}`}>
      <div style={styles.wrapper}>
        <div
          className='row justify-content-between no-gutters saps-input-slider__header'
          style={{ paddingRight: '1rem' }}
        >
          <div className='col-12 col-md-auto'>
            <label className={`saps-label ${props.customLabelCSSClass ?? ''}`}>{props.title}</label>
          </div>
          <div className='col-12 col-md-auto'>
            <div className='saps-slider-range'>
              <InputSliderTextInput
                parentValue={inputFromValue?.toString() ?? '0'}
                setRangeValue={handleInputBlurMin}
                rangeSide='min'
                maxValue={props.maxValue}
                maxLabel={props.maxLabel ?? ''}
                thousandDelimiter={props.thousandDelimiter}
              />
              -
              <InputSliderTextInput
                parentValue={
                  inputToValue?.toString() ?? props.maxLabel ?? props.maxValue.toString()
                }
                setRangeValue={handleInputBlurMax}
                rangeSide='max'
                units={props.units}
                maxValue={props.maxValue}
                maxLabel={props.maxLabel ?? props.maxValue.toString()}
                thousandDelimiter={props.thousandDelimiter}
              />
            </div>
          </div>
        </div>
        <Slider
          range
          value={[currRangeMin ?? props.minValue, currRangeMax ?? props.maxValue]}
          allowCross={false}
          min={props.minValue}
          max={props.maxValue}
          step={step}
          onChange={value => {
            handleOnChange(value as [number, number])
          }}
          onAfterChange={value => {
            handleOnAfterChange(value as [number, number])
          }}
        />
        <div className='row no-gutters justify-content-between saps-mt-5 d-none d-md-flex'>
          <div className='col-auto'>
            <p className='saps-label saps-color-grey-350 saps-mb-0'>{props.minLabel}</p>
          </div>
          <div className='col-auto'>
            <p className='saps-label saps-color-grey-350 saps-mb-0'>
              {props.maxLabel ?? `${props.maxValue} ${props.units}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})

const styles = {
  wrapper: { width: 'auto', margin: 0 },
}

export default InputSlider
