import React, { RefObject } from 'react'

type Props = {
  optionsLength: number
  style?: React.CSSProperties
  children: (a: any, b: any) => any
  className: string
  handleChange: (val: { focusedOptionIndex: number }) => void
  onEscapeClick?: () => void
  isDropdownActive: boolean
}
/**
 * `<DropdownOptions ... />`
 * handle wrapper UI view of box with `<Option />[]`
 *
 * This Component also handle selecting of items via keyboard *state*
 *
 * we can't use function component with `hooks` because we need array of refs
 * (more about the issue)[https://github.com/facebook/react/issues/14072]
 */
type State = {
  focusedOptionIndex: null | number
}
export default class DropdownOptions extends React.Component<Props, State> {
  optionsWrapper: RefObject<HTMLDivElement>
  optionsRefs: RefObject<HTMLDivElement>[]

  constructor(props: Props) {
    super(props)
    this.state = {
      focusedOptionIndex: null,
    }
    this.optionsWrapper = React.createRef<HTMLDivElement>()
    // for changing `optionalsRefs` length -> use:
    // > `key={iterableIndexCategoryItems}`
    // > https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
    this.optionsRefs = Array.from({ length: this.props.optionsLength }).map(() => React.createRef())
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeypress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeypress)
  }

  _handleKeypress = (e: any) => {
    const { focusedOptionIndex } = this.state
    const { handleChange, optionsLength, isDropdownActive, onEscapeClick } = this.props
    if (isDropdownActive) {
      switch (e.key) {
        case 'Enter': {
          e.preventDefault()
          handleChange({ focusedOptionIndex: focusedOptionIndex! })
          break
        }
        case 'ArrowDown': {
          e.preventDefault()
          this.setState(
            ({ focusedOptionIndex }) => ({
              focusedOptionIndex:
                focusedOptionIndex === null
                  ? 0
                  : focusedOptionIndex > optionsLength - 1 - 1
                    ? 0
                    : focusedOptionIndex + 1,
            }),
            () => {
              const activeOptionRef = this.optionsRefs[this.state.focusedOptionIndex!]
              this._scrollToItemInView(activeOptionRef)
            }
          )
          break
        }
        case 'ArrowUp': {
          e.preventDefault()
          this.setState(
            ({ focusedOptionIndex }) => ({
              focusedOptionIndex:
                focusedOptionIndex === null
                  ? optionsLength - 1
                  : focusedOptionIndex === 0
                    ? optionsLength - 1
                    : focusedOptionIndex - 1,
            }),
            () => {
              const activeOptionRef = this.optionsRefs[this.state.focusedOptionIndex!]
              this._scrollToItemInView(activeOptionRef)
            }
          )
          break
        }
        case 'Escape':
          e.preventDefault()
          this.setState({ focusedOptionIndex: null })
          onEscapeClick && onEscapeClick()
          break
        default:
          break
      }
    }
  }

  /**
   * TODO: performance problem with `css reflow` I guess
   * > [css reflow](https://gist.github.com/paulirish/5d52fb081b3570c81e3a)
   * different libraries do similar behavior like that
   * > [react-select example](https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/utils.js#L190)
   * > [react-select _scrollToItemInView](https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L589)
   */
  _scrollToItemInView = (activeOptionRef: any) => {
    if (activeOptionRef) {
      const isActiveItemAboveView =
        activeOptionRef.offsetTop < Math.floor(this.optionsWrapper.current!.scrollTop)

      if (isActiveItemAboveView) {
        this.optionsWrapper.current!.scrollTop = activeOptionRef.offsetTop
      }

      const isActiveItemUnderView =
        activeOptionRef.offsetTop - Math.floor(this.optionsWrapper.current!.scrollTop) >=
        this.optionsWrapper.current!.clientHeight - activeOptionRef.clientHeight

      if (isActiveItemUnderView) {
        this.optionsWrapper.current!.scrollTop =
          activeOptionRef.offsetTop -
          this.optionsWrapper.current!.clientHeight +
          activeOptionRef.clientHeight
      }
    }
  }

  render() {
    const { children: childrenFn, style, className } = this.props
    const { focusedOptionIndex } = this.state
    // -> have to have Large capital letter
    // make React Component from children function
    const ChildrenComponent = React.forwardRef(childrenFn)

    return (
      <div
        className={className || ''}
        ref={this.optionsWrapper}
        /**
         * we have to set position `relative`/`absolute` because we need to set `offsetParent`
         * > https://developer.mozilla.org/en-US/docs/Web/API/HTMLelement/offsetParent
         * for items `.offsetTop`
         * > https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop
         **/
        style={style}
      >
        {ChildrenComponent && (
          <ChildrenComponent
            focusedOptionIndex={focusedOptionIndex}
            // @ts-expect-error
            ref={this.optionsRefs}
          />
        )}
      </div>
    )
  }
}
