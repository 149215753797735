import { DropdownOptionsWrapper } from '../splus-lib/components'
import { ResultItem } from './ResultItem'
import { SearchTermState } from './HeaderSearch'
import { getTranslation } from '../splus-lib/utils/languageUtils'
import { gtmDataLayerPush } from '../splus-lib/utils/googleTagManager/utils'
import { notNullable } from '../splus-lib/utils'
import React from 'react'

export const CATEGORIES_ORDER = ['suggestions', 'cars', 'dealers', 'titles'] as const

const categoriesTitles = {
  dealers: getTranslation('header.search.category.dealers'),
  titles: getTranslation('header.search.category.pages'),
}

const getActiveStyles = (isActive: boolean) => (isActive ? { background: '#EEE' } : {})

type Props = {
  results: SearchTermState
  suggestionsHandler: (suggestion: string) => void
  searchIsCalled: boolean
  onEscapeClick: () => void
  storedInputValue: any
}
const ResultsList = ({
  results,
  suggestionsHandler,
  searchIsCalled,
  onEscapeClick,
  storedInputValue,
}: Props) => {
  let iterableIndexCategoryItems = 0

  const categoryResultsPreview = CATEGORIES_ORDER.map(category => ({
    name: category,
    results: Array.isArray(results[category])
      ? results[category].map(categoryResult => ({
          ...categoryResult,
          _orderIndex: iterableIndexCategoryItems++,
        }))
      : null,
  })).filter(category => category.results && category.results.length > 0)

  const joinedFlatResults = categoryResultsPreview.flatMap(i => i.results).filter(notNullable)

  const totalResultsLength = joinedFlatResults.length

  const sendGAEvent = (url: string) => {
    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Search',
      eventLabel: url,
      'appweb.Name': 'ms_search',
    })
  }

  const getTextSuggestion = (suggestion: string) => {
    return suggestion.replace(/\[match\]/g, '').replace(/\[\/match\]/g, '')
  }

  const onSuggestionsClick = (suggestion: string) => {
    sendGAEvent(suggestion)
    suggestionsHandler(getTextSuggestion(suggestion))
  }

  const onLinkClick = (url: string) => {
    sendGAEvent(url)

    window.location.href = url
    // TODO: may the local push be there instead of location href?
    // I don'think so because /list is not listening for URL changes
    // router.push(url)
  }

  return (
    <DropdownOptionsWrapper
      key={totalResultsLength}
      optionsLength={totalResultsLength}
      handleChange={({ focusedOptionIndex }) => {
        const selectedLink = joinedFlatResults[focusedOptionIndex].linkUrl
        const selectedSuggestion = joinedFlatResults[focusedOptionIndex].suggestion
        if (selectedLink !== null) {
          onLinkClick(selectedLink)
        } else {
          onSuggestionsClick(selectedSuggestion)
        }
      }}
      onEscapeClick={() => onEscapeClick()}
      className='saps-header-search__results'
      isDropdownActive={searchIsCalled}
    >
      {({ focusedOptionIndex }, optionsRefs) => (
        <div>
          {totalResultsLength === 0 && searchIsCalled && (
            <p className='saps-p saps-bold saps-mt-20'>
              {getTranslation('header.search.not.found')}
            </p>
          )}
          {totalResultsLength > 0 &&
            searchIsCalled &&
            categoryResultsPreview.map((category, categoryIndex) => (
              <div
                // @ts-expect-error
                className={category === 'suggestions' ? '' : 'saps-header-search__category-wrapper'}
                key={`searchResultCategory_${categoryIndex}`}
                data-component='header-search'
                data-item=''
              >
                {(category.results ?? []).length > 0 && (
                  <div>
                    {category.name === 'suggestions' && ( // shows suggestions
                      <div className={`saps-header-search__suggestions`}>
                        <ul className='saps-header-search__suggestions-list'>
                          {category.results?.map((resultItem, resultItemIndex) => (
                            <li
                              ref={ref => (optionsRefs[resultItem._orderIndex] = ref)}
                              style={getActiveStyles(resultItem._orderIndex === focusedOptionIndex)}
                              className='saps-header-search__suggestions-list-item'
                              key={`searchResultCategory_${categoryIndex}_${resultItemIndex}`}
                            >
                              <ResultItem
                                item={resultItem}
                                category={category.name}
                                onLinkClick={onLinkClick}
                                onSuggestionsClick={onSuggestionsClick}
                                getTextSuggestion={getTextSuggestion}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {
                      // shows results
                      category.name !== 'suggestions' && (
                        <div className={`saps-header-search__category`}>
                          {
                            // @ts-expect-error
                            categoriesTitles[category.name] && (
                              <h3 className='saps-header-search__category-title'>
                                {
                                  // @ts-expect-error
                                  categoriesTitles[category.name]
                                }
                              </h3>
                            )
                          }
                          <ul className='saps-header-search__category-list'>
                            {category.results?.map((resultItem, resultItemIndex) => (
                              <li
                                ref={ref => (optionsRefs[resultItem._orderIndex] = ref)}
                                style={getActiveStyles(
                                  resultItem._orderIndex === focusedOptionIndex
                                )}
                                className='saps-header-search__category-list-item'
                                key={`searchResultCategory_${categoryIndex}_${resultItemIndex}`}
                              >
                                <ResultItem
                                  item={resultItem}
                                  category={category.name}
                                  onLinkClick={onLinkClick}
                                  onSuggestionsClick={onSuggestionsClick}
                                  getTextSuggestion={getTextSuggestion}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                    }
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </DropdownOptionsWrapper>
  )
}

export default ResultsList
