export {
  buildUrl,
  noModulo,
  genArrBySize,
  isNumeric,
  capitalize,
  parseModelLevel,
  kwToHp,
  litresFromCm3,
  removeFromArray,
  getPaintTypeTranslation,
  isNilOrEmpty,
  findNumberInString,
  parseGlobalId,
  filterOutEmptyObjValues,
} from './globalUtils'

export {
  transformToNumber,
  sortByNestedName,
  sortByNestedID,
  sortBySubNestedKey,
  orderArrayOfObjects,
  basicFilter,
  czechMapNuc,
  getNameById,
  parseSuggestParams,
  parseOtherAttrName,
  validateInput,
  sortGpsListByDistance,
  slovakMapNuc,
} from './api/apiUtils'

export {
  filterTransmissionList,
  groupTransmissionIds,
  ungroupTransmissionIds,
  getTransmissionTypeName,
} from './transmissionGrouping'

export { getRecommendedModelsByCars } from './api/getRecommendedModelsByCars'
export { ScreenBreakPointsEnum } from './enums'
export { IsMobile, IsMediumDeviceDown, SaplusResizeProvider } from './SaplusResizeProvider'

export { default as useInfiniteScroll } from './hooks/useInfiniteScroll'
export { ScrollSpyProvider } from './hooks/useScrollSpy'
export { sendGTMDataWithNiceFormat } from './googleTagManager/sendFilterData'
export { genericHookContextBuilder } from './genericHookContextBuilder'

export { notNullable } from './typeGuards'
