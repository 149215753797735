import { Action } from '../kentico-types/action'
import { AdvantagesAndFinancing } from '../kentico-types/advantages_and_financing'
import { AlternativeDrives } from '../kentico-types/alternative_drives'
import { Branches } from '../kentico-types/branches'
import { BuyCar } from '../kentico-types/buy_car'
import { CULTURE } from '../src/splus-lib/utils/languageUtils'
import { CarConditions } from '../kentico-types/car_conditions'
import { Configuration } from '../kentico-types/configuration'
import { Contact } from '../kentico-types/contact'
import { Cookies } from '../kentico-types/cookies'
import { Copyright } from '../kentico-types/copyright'
import { DeliveryClient, TypeResolver } from '@kentico/kontent-delivery'
import { DoubleRichtextWithTitle } from '../kentico-types/double_richtext_with_title'
import { EquipmentItem } from '../kentico-types/equipment_item'
import { ExtendedWarranty } from '../kentico-types/extended_warranty'
import { Faq } from '../kentico-types/faq'
import { FileWithText } from '../kentico-types/file_with_text'
import { Financing } from '../kentico-types/financing'
import { Homepage } from '../kentico-types/homepage'
import { HowToChooseCar } from '../kentico-types/how_to_choose_car'
import { Insurance } from '../kentico-types/insurance'
import { LinkWithText } from '../kentico-types/link_with_text'
import { ModelsOverview } from '../kentico-types/models_overview'
import { OSkodaPlus } from '../kentico-types/o_skoda_plus'
import { OneYearCar } from '../kentico-types/one_year_car'
import { Partners } from '../kentico-types/partners'
import { PersonalInformationProcessing } from '../kentico-types/personal_information_processing'
import { PictureWithLink } from '../kentico-types/picture_with_link'
import { PictureWithText } from '../kentico-types/picture_with_text'
import { PictureWithTextAndDescription } from '../kentico-types/picture_with_text_and_description'
import { PictureWithTextAndLink } from '../kentico-types/picture_with_text_and_link'
import { ProgramsSkodaplus } from '../kentico-types/programs_skodaplus'
import { RichTextItem } from '../kentico-types/rich_text_item'
import { RichtextWithText } from '../kentico-types/richtext_with_text'
import { SellingCar } from '../kentico-types/selling_car'
import { Tab } from '../kentico-types/tab'
import { TitleWithText } from '../kentico-types/title_with_text'
import { TriedCars } from '../kentico-types/tried_cars'
import { UsedCars } from '../kentico-types/used_cars'
import { Watchdog } from '../kentico-types/watchdog'
import safeJsonStringify from 'safe-json-stringify'

const kenticoClient = new DeliveryClient({
  projectId: process.env.KENTICO_PROJECT_ID ?? '',
  previewApiKey: process.env.KENTICO_PREVIEW_API_KEY ?? '',
  typeResolvers: [
    new TypeResolver('configuration', () => new Configuration()),
    new TypeResolver('action', () => new Action()),
    new TypeResolver('advantages_and_financing', () => new AdvantagesAndFinancing()),
    new TypeResolver('alternative_drives', () => new AlternativeDrives()),
    new TypeResolver('branches', () => new Branches()),
    new TypeResolver('buy_car', () => new BuyCar()),
    new TypeResolver('car_conditions', () => new CarConditions()),
    new TypeResolver('contact', () => new Contact()),
    new TypeResolver('cookies', () => new Cookies()),
    new TypeResolver('copyright', () => new Copyright()),
    new TypeResolver('double_richtext_with_title', () => new DoubleRichtextWithTitle()),
    new TypeResolver('equipment_item', () => new EquipmentItem()),
    new TypeResolver('extended_warranty', () => new ExtendedWarranty()),
    new TypeResolver('faq', () => new Faq()),
    new TypeResolver('file_with_text', () => new FileWithText()),
    new TypeResolver('financing', () => new Financing()),
    new TypeResolver('homepage', () => new Homepage()),
    new TypeResolver('how_to_choose_car', () => new HowToChooseCar()),
    new TypeResolver('insurance', () => new Insurance()),
    new TypeResolver('link_with_text', () => new LinkWithText()),
    new TypeResolver('models_overview', () => new ModelsOverview()),
    new TypeResolver('o_skoda_plus', () => new OSkodaPlus()),
    new TypeResolver('one_year_car', () => new OneYearCar()),
    new TypeResolver('partners', () => new Partners()),
    new TypeResolver('personal_information_processing', () => new PersonalInformationProcessing()),
    new TypeResolver('picture_with_link', () => new PictureWithLink()),
    new TypeResolver('picture_with_text', () => new PictureWithText()),
    new TypeResolver(
      'picture_with_text_and_description',
      () => new PictureWithTextAndDescription()
    ),
    new TypeResolver('picture_with_text_and_link', () => new PictureWithTextAndLink()),
    new TypeResolver('programs_skodaplus', () => new ProgramsSkodaplus()),
    new TypeResolver('rich_text_item', () => new RichTextItem()),
    new TypeResolver('richtext_with_text', () => new RichtextWithText()),
    new TypeResolver('selling_car', () => new SellingCar()),
    new TypeResolver('tab', () => new Tab()),
    new TypeResolver('title_with_text', () => new TitleWithText()),
    new TypeResolver('tried_cars', () => new TriedCars()),
    new TypeResolver('used_cars', () => new UsedCars()),
    new TypeResolver('watchdog', () => new Watchdog()),
  ],
})

export const getContent = async (type: string) => {
  return await kenticoClient
    .items()
    .type(type)
    .queryConfig({
      usePreviewMode: false,
    })
    .languageParameter(CULTURE)
    .toPromise()
    .then(res => {
      const data = {
        content: null,
        linkedContent: null,
      }
      if (res.items[0]) {
        const stringifiedData = safeJsonStringify(res.items[0])
        data.content = JSON.parse(stringifiedData)
      }
      if (res.linkedItems) {
        const stringifiedData = safeJsonStringify(res.linkedItems)
        data.linkedContent = JSON.parse(stringifiedData)
      }
      return data
    })
}
export const getEquipment = async () => {
  return await kenticoClient
    .items()
    .type('equipment_item')
    .queryConfig({
      usePreviewMode: false,
    })
    .languageParameter(CULTURE)
    .toPromise()
    .then(res => {
      return res.items.map((item: any) => {
        const o = JSON.parse(safeJsonStringify(item))
        return {
          images: o.pictures.value.map((i: any) => i.url),
          key: o.key.value,
          text: o.description.value,
          title: o.title.value,
          videoUrl: o.videoUrl.value,
        }
      })
    })
}

export const getCarConditions = async () => {
  return await kenticoClient
    .items()
    .type('car_conditions')
    .queryConfig({
      usePreviewMode: false,
    })
    .languageParameter(CULTURE)
    .toPromise()
    .then(res => {
      if (res.items) {
        const items: string[] = res.items[0].carConditionsItems.linkedItemCodenames
        if (res.linkedItems) {
          const stringifiedData = safeJsonStringify(res.linkedItems)
          const linkedContent = JSON.parse(stringifiedData)
          return items.map((p, i) => {
            return linkedContent[p]
          })
        } else {
          return null
        }
      } else {
        return null
      }
    })
}
