export const urls = {
  root: () => '/',
  list: () => '/list',
  about: () => '/about',
  notFound404: () => '/page-not-found',
  faq: () => '/faq',
  contact: () => '/contact',
  buyingVehicle: () => '/buying-vehicle',
  financing: () => '/financing',
  sellingVehicle: (mid?: string) => `/selling-vehicle${mid ? `?mid=${mid}` : ''}`,
  yearOldCars: () => '/year-old-cars',
  triedCars: () => 'tried-cars',
  usedCars: () => '/used-cars',
  alternativeDrives: () => '/alternative-drives',

  pageWatchdog: () => '/page-watchdog',
  overviewModels: () => '/overview-models',
  extendedWarranty: () => '/extended-warranty',
  insurance: () => '/insurance',

  processingOfPersonalData: (hash?: string) =>
    '/processing-of-personal-data' + (hash ? `#${hash}` : ''),

  // Deprecated now using skodaauto cookies
  cookies: () => '/cookies',
  copyright: () => '/copyright',
  sitemap: () => '/sitemap',
  branches: (mid?: string) => `/branches${mid ? `?mid=${mid}&dealer=${mid}` : ''}`,

  carComparison: () => '/car-comparison',

  carDetail: (a: { carId: string; prettyUrl: string; query?: string }) =>
    `/Car/${a.carId}/${a.prettyUrl}${a.query ? `/?${a.query}` : ''}`,

  dealerDetail: (dealerId: string, query?: string) =>
    `/dealer/${dealerId}${query ? `?${query}` : ''}`,
}
