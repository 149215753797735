import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class LinkWithText extends ContentItem {
  public text: Elements.RichTextElement
  public url: Elements.TextElement
}
