import { LANGUAGE } from '../utils/languageUtils'
import { withNoSSR } from '../../components/withNoSSR'
import React from 'react'

type Props = {
  className?: string
  carsPerPage?: number
  ids: string[]
  children: React.ReactNode
  blank: boolean
}
const PrintPDF = withNoSSR((props: Props) => {
  const carsPerPage = props.carsPerPage ?? 1

  if (props.ids.length === 0) {
    return <div />
  }

  const getUrl = () => {
    const url = new URL('/public/cars', window.location.href.split(/\?|#/)[0])
    props.ids.forEach(id => url.searchParams.append('carId', id))
    url.searchParams.set('carsPerPage', carsPerPage.toString())
    url.searchParams.set('lang', LANGUAGE)
    return url
  }

  const url = getUrl()

  if (url === null) {
    return <div />
  }

  return props.blank ? (
    <div>
      <a href={url.href} target='_blank' rel='noopener noreferrer' className={props.className}>
        {props.children}
      </a>
    </div>
  ) : (
    <div>
      <a href={url.href} className={props.className}>
        {props.children}
      </a>
    </div>
  )
})

export default PrintPDF
