import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class DoubleRichtextWithTitle extends ContentItem {
  public title: Elements.RichTextElement
  public leftColumn: Elements.RichTextElement
  public rightColumn: Elements.RichTextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'left_column') {
          return 'leftColumn'
        }
        if (elementName === 'right_column') {
          return 'rightColumn'
        }
        return elementName
      },
    })
  }
}
