import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Copyright extends ContentItem {
  public contentIntroduction: Elements.RichTextElement
  public contentPicture: Elements.AssetsElement
  public contentDescription: Elements.RichTextElement
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'content_introduction') {
          return 'contentIntroduction'
        }
        if (elementName === 'content_picture') {
          return 'contentPicture'
        }
        if (elementName === 'content_description') {
          return 'contentDescription'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
