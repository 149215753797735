import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class CarConditions extends ContentItem {
  public carConditionsItems: Elements.RichTextElement
  public objectType: Elements.TaxonomyElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'car_conditions_items') {
          return 'carConditionsItems'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        return elementName
      },
    })
  }
}
