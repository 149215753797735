import { LOCALE, getTranslation } from '../../splus-lib/utils/languageUtils'
import React from 'react'

const LiveChat = () => {
  if (LOCALE === 'sk') return null
  return (
    <div className='saps-live-chat'>
      <div id='saps-live-chat-window-id' className='saps-live-chat-window'>
        <div className='saps-live-chat-window__close'>✖</div>
        <iframe
          className='saps-live-chat-window__iframe'
          src='about:blank'
          data-src='https://ws.skoda-auto.com/WebChat/hppcwis.dll?varUserRequest=REQ_WEBCHAT_MAIN
          &varUserLanguage=english&varBusinessUnitName=CC&varSource=SkodaPlusCZ%20'
          id='liveChatIframe'
        />
      </div>
      <button type='button' className='saps-live-chat-toggle'>
        <span className='saps-live-chat-toggle__text'>{getTranslation('livechat.text')}</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
          className='saps-live-chat-toggle__icon'
        >
          <path
            className='saps-live-chat-toggle__icon-path'
            d='M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5A24 24 0 0 0
            24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256
            32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7
            19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208
            160-93.3 160-208 160z'
          />
        </svg>
      </button>
    </div>
  )
}
export default LiveChat
