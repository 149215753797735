/**
 * Screen breakpoint following bootstrap standards
 * https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
 */
export const ScreenBreakPointsEnum = {
  /**  Extra small devices (portrait phones, less than 576px) */
  XS: 575.98,
  /** Small devices (landscape phones, less than 768px) */
  SM: 767.98,
  /** Medium devices (tablets, less than 992px) */
  MD: 991.98,
  /** Large devices (desktops, less than 1200px) */
  LG: 1199.98,
}
