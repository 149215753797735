import React from 'react'

type Props = {
  color?: string
  width?: number
  height?: number
}

const CebiaStar = (props: Props) => (
  <div className='saps-car__cebia-star'>
    <svg
      className='cebia-star__icon'
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ?? 22}
      height={props.height ?? 22}
      viewBox='0 0 24 24'
    >
      <path
        className='cebia-star__icon-fill'
        fill={props.color}
        d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'
      />
    </svg>
  </div>
)

export default CebiaStar
