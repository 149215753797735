import { gtmDataLayerPush } from '../splus-lib/utils/googleTagManager/utils'
import Link from 'next/link'
import React, { ComponentPropsWithoutRef } from 'react'

export const SPLink = (props: {
  href: string
  className?: string
  children?: React.ReactNode
  onClick?: (e: any) => void
  onMouseOver?: (e: any) => void
  onMouseOut?: (e: any) => void
  title?: any
  style?: React.CSSProperties
  dangerouslySetInnerHTML?: any
  market?: {
    Placeholder1?: string
    Placeholder2?: string
  }
}) => {
  const { href, onClick, children, market, ...restProps } = props

  return (
    <Link legacyBehavior href={href}>
      <a
        {...restProps}
        onClick={e => {
          onClick?.(e)

          gtmDataLayerPush({
            event: 'trackEvent',
            eventCategory: 'Microsite - skodaplus',
            eventAction: 'Link internal',
            eventLabel: href,
            'appweb.Name': 'ms_link_internal',
            market: {
              ...market,
            },
          })
        }}
      >
        {children}
      </a>
    </Link>
  )
}

export const AHrefExternal = (props: ComponentPropsWithoutRef<'a'>) => {
  return (
    <a
      {...props}
      onClick={e => {
        gtmDataLayerPush({
          event: 'trackEvent',
          eventCategory: 'Microsite - skodaplus',
          eventAction: 'External link',
          eventLabel: props.href,
          'appweb.Name': 'ms_external_link',
        })

        props?.onClick?.(e)
      }}
    />
  )
}

export const ATel = (
  props: { phone?: string | null | undefined; dealerId?: string; carId?: string } & Omit<
    ComponentPropsWithoutRef<'a'>,
    'href'
  >
) => {
  return (
    <a
      {...props}
      href={`tel:${props.phone}`}
      onClick={e => {
        gtmDataLayerPush({
          event: 'trackEvent',
          eventCategory: 'Microsite - skodaplus',
          eventAction: 'Click',
          eventLabel: 'Telefonní číslo',
          'appweb.Name': 'ms_click_telefonni_cislo',
          ...{
            market: {
              ...(props.dealerId ? { Placeholder1: `260-${props.dealerId}` } : {}),
              ...(props.carId ? { Placeholder2: props.carId.replace(/Car-/g, '') } : {}),
            },
          },
        })
        props?.onClick?.(e)
      }}
    />
  )
}
