import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EquipmentItem extends ContentItem {
  public key: Elements.TextElement
  public title: Elements.RichTextElement
  public description: Elements.RichTextElement
  public pictures: Elements.AssetsElement
  public videoUrl: Elements.TextElement
  public objectType: Elements.TaxonomyElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'video_url') {
          return 'videoUrl'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        return elementName
      },
    })
  }
}
