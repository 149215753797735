import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class HowToChooseCar extends ContentItem {
  public howToChooseCarText: Elements.RichTextElement
  public howToChooseCarPicture: Elements.AssetsElement
  public questionsItems: Elements.RichTextElement
  public contactInfo: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'how_to_choose_car_text') {
          return 'howToChooseCarText'
        }
        if (elementName === 'how_to_choose_car_picture') {
          return 'howToChooseCarPicture'
        }
        if (elementName === 'questions_items') {
          return 'questionsItems'
        }
        if (elementName === 'contact_info') {
          return 'contactInfo'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
