// -------------------------------------
// ------- transmission grouping -------
// -------------------------------------

// transmission_1 & GROUPED_AUTOMAT_TRANSMISSIONS are two main groups
const transmission_1 = 'transmission_1'
const GROUPED_AUTOMAT_TRANSMISSIONS = 'GROUPED_AUTOMAT_TRANSMISSIONS'

// transmission 2-7 is part of GROUPED_AUTOMAT_TRANSMISSIONS
const transmission_2 = 'transmission_2'
const transmission_3 = 'transmission_3'
const transmission_5 = 'transmission_5'
const transmission_4 = 'transmission_4'
const transmission_6 = 'transmission_6'
const transmission_7 = 'transmission_7'

/**
 * Workaround to fix bug https://jira.cleverlance.com/jira/browse/SAPLUS-480
 * It filters the list to only show "Manual" and "Automatic" transmission with correct totals
 * This solution should be reviewed in the future to remove the hardcoded values
 */
type TransList = {
  id: string
  carsCount?: number
}[]

export const filterTransmissionList = (transList: TransList = []) => [
  {
    id: transmission_1,
    name: 'manuál',
    carsCount: transList
      ?.filter(transmission => transmission?.id === transmission_1)
      .reduce((pre, curr) => pre + (curr?.carsCount ?? 0), 0),
  },
  {
    id: GROUPED_AUTOMAT_TRANSMISSIONS,
    name: 'automat',
    carsCount: transList
      ?.filter(transmission => transmission?.id !== transmission_1)
      .reduce((pre, curr) => pre + (curr?.carsCount ?? 0), 0),
  },
]

export const groupTransmissionIds = (transmissionsIds: string[] = []) => {
  const groupedTransmissionsIds = []

  if (transmissionsIds.includes(transmission_1)) {
    groupedTransmissionsIds.push(transmission_1)
    // check if user selected something from automatic transmissions
    if (transmissionsIds.length > 1) {
      groupedTransmissionsIds.push(GROUPED_AUTOMAT_TRANSMISSIONS)
    }
  } else {
    // check if user selected something from automatic transmissions
    if (transmissionsIds.length > 0) {
      groupedTransmissionsIds.push(GROUPED_AUTOMAT_TRANSMISSIONS)
    }
  }

  return groupedTransmissionsIds
}

export const ungroupTransmissionIds = (groupedTransmissionIds: string[] = []) => {
  let ungroupTransmissionIds = []
  if (groupedTransmissionIds.includes(transmission_1)) {
    ungroupTransmissionIds.push(transmission_1)
  }
  if (groupedTransmissionIds.includes(GROUPED_AUTOMAT_TRANSMISSIONS)) {
    ungroupTransmissionIds = [
      ...ungroupTransmissionIds,
      transmission_2,
      transmission_3,
      transmission_5,
      transmission_4,
      transmission_6,
      transmission_7,
    ]
  }
  return ungroupTransmissionIds
}

export const getTransmissionTypeName = (transmissionId: string) => {
  return transmissionId === transmission_1 ? 'manuál' : 'automat'
}
