/**
 * @generated SignedSource<<b994c9cd67934329c8fe0c79610dd07b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ColorsFilter_data$data = {
  readonly codeBook: ReadonlyArray<{
    readonly id: string;
    readonly value: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ColorsFilter_data";
};
export type ColorsFilter_data$key = {
  readonly " $data"?: ColorsFilter_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ColorsFilter_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ColorsFilter_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "codeBookName",
          "value": "COLOR"
        }
      ],
      "concreteType": "CodeBookItem",
      "kind": "LinkedField",
      "name": "codeBook",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "lang",
              "variableName": "lang"
            }
          ],
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": "codeBook(codeBookName:\"COLOR\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "395e58a250e47effa754a1e0f2e79474";

export default node;
