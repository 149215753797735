import React from 'react'

type Props = {
  isValid: boolean
  name: string
  placeholder: string
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea = (props: Props) => (
  <textarea
    cols={60}
    rows={3}
    name={props.name}
    className={`saps-textarea ${props.isValid ? '' : ' saps-textarea--invalid'}`}
    onChange={props.handleChange}
    placeholder={props.placeholder}
  />
)

export default TextArea
