import { ReactComponent as IconDropdown } from './svg/dropdown.svg'
import { capitalize, notNullable } from '../../utils'
import DropdownOptionsWrapper from './DropdownOptionsWrapper'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const styles = {
  lightBackground: { background: '#EEE' },
}
type OptionItemProps = {
  name: string
  checked: boolean
  option: {
    id: string
    name: string
    value: boolean
    carsCount?: number
  }
  isActiveHover: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  showCount: boolean
}

const OptionItem = React.memo((props: OptionItemProps) => {
  return (
    <li
      style={props.isActiveHover ? styles.lightBackground : undefined}
      className='saps-inputlist-dropdown__list-item'
    >
      <label className='saps-inputlist-dropdown__list-item-label'>
        <input
          type='checkbox'
          name={props.name}
          className='saps-input-checkbox'
          value={props.option.id}
          checked={props.checked}
          onChange={props.handleChange}
        />
        <span className='saps-checkbox-title'>
          {capitalize(props.option.name) || capitalize(props.option.value)}{' '}
          {props.showCount ? `(${props.option.carsCount || 0}x)` : ''}
        </span>
      </label>
    </li>
  )
})

type Props = {
  name: string
  placeholder: string
  showCount: boolean
  optionsList: any[]
  customCSSClass?: string
  handleChange: (data: { value: string; name: string; checked: boolean }) => void
  selectedOptions: any[]
  minContentDropdownWidth?: number
  fallbackOptions?: any[]
}

const InputCheckboxDropdown = (props: Props) => {
  const {
    selectedOptions = [],
    name,
    optionsList = [],
    fallbackOptions = [],
    showCount,
    handleChange,
    minContentDropdownWidth,
    customCSSClass = '',
    placeholder,
  } = props

  const [dropdownOpenState, setDropdownOpenState] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLUListElement | null>(null)

  // TODO: make reusable hook
  useEffect(
    () => {
      const handleClickAnywhere = (e: MouseEvent) => {
        e.stopPropagation()

        if (dropdownOpenState === false) return
        // @ts-expect-error no idea what is this code doing
        if (e.pointerType !== 'mouse') return

        const userClickedOutsideOfDropdown = !wrapperRef.current?.contains(e.target as Node)

        if (userClickedOutsideOfDropdown) {
          setDropdownOpenState(false)
        }
      }
      document.addEventListener('click', handleClickAnywhere)
      return () => document.removeEventListener('click', handleClickAnywhere)
    },
    [dropdownOpenState]
    // where is the second parameter?
    // `useEffect` can't check diffs of `useRef`
    // > https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  )

  const toggleDropdownOpenState = () => {
    setDropdownOpenState(options.length === 0 ? false : !dropdownOpenState)
  }

  const checkedIdsOutOfFilter = selectedOptions
    .filter(selectedOptionId => !optionsList.find(option => option.id === selectedOptionId))
    .map(selectedOptionId => fallbackOptions.find(option => option.id === selectedOptionId))
    .filter(notNullable)

  const options = [...checkedIdsOutOfFilter, ...optionsList]

  const handleOptionChange = useCallback(
    (e: any) => {
      handleChange({
        value: e.target.value,
        checked: e.target.checked,
        name: e.target.name,
      })
    },
    [handleChange]
  )

  const onEscapeClick = useCallback(() => {
    setDropdownOpenState(false)
  }, [setDropdownOpenState])

  return (
    <div className={`saps-inputlist-dropdown ${customCSSClass}`} ref={wrapperRef}>
      <a className='saps-inputlist-dropdown__toggle' onClick={() => toggleDropdownOpenState()}>
        <span className='saps-inputlist-dropdown__toggle-text'>
          {placeholder} {selectedOptions.length > 0 ? `(${selectedOptions.length}x)` : null}
        </span>
        {options.length !== 0 && (
          <span className='saps-inputlist-dropdown__toggle-icon'>
            <IconDropdown />
          </span>
        )}
      </a>
      <DropdownOptionsWrapper
        key={options.length}
        optionsLength={options.length}
        handleChange={({ focusedOptionIndex }) => {
          handleChange({
            value: options[focusedOptionIndex].id,
            checked: !selectedOptions.includes(options[focusedOptionIndex].id),
            name,
          })
        }}
        className={`saps-inputlist-dropdown__list-wrapper ${
          dropdownOpenState && options.length !== 0 ? ' saps-dialog-active ' : ''
        }`}
        onEscapeClick={onEscapeClick}
        isDropdownActive={dropdownOpenState}
        style={minContentDropdownWidth ? { width: minContentDropdownWidth } : undefined}
      >
        {({ focusedOptionIndex }, optionsRefs) => (
          <ul className='saps-inputlist-dropdown__list' ref={dropdownRef}>
            {options.map((option, index) => {
              let c = showCount
              if (option.isMother) {
                c = false
              }
              return (
                <div key={option.id} ref={ref => (optionsRefs[index] = ref)}>
                  <OptionItem
                    isActiveHover={focusedOptionIndex === index}
                    name={name}
                    option={option}
                    handleChange={handleOptionChange}
                    showCount={c}
                    checked={selectedOptions.includes(option.id)}
                  />
                </div>
              )
            })}
          </ul>
        )}
      </DropdownOptionsWrapper>
    </div>
  )
}

export default React.memo(InputCheckboxDropdown)
