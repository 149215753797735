import React from 'react'

type Props = {
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}
const Arrow = (props: Props) => (
  <button {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='40'
      viewBox='0 0 256 512'
      className='saps-cars-carousel__arrow-icon'
    >
      {' '}
      <path
        d='M194.5 419L28 252.5 194.5 86q4.5-4 10.25-4T215 86q4 4 4 10t-4 10L68.5 252.5 215 399q4 4 4 10t-4 10-9.75 4-10.75-4z'
        className='saps-cars-carousel__arrow-icon-path'
      />{' '}
    </svg>
  </button>
)

export default Arrow
