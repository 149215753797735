import { API_DOMAIN, LANGUAGE, getTranslation } from '../splus-lib/utils/languageUtils'
import { parseOtherAttrName, parseSuggestParams } from '../splus-lib/utils'
import React, { useRef, useState } from 'react'
import ResultsList, { CATEGORIES_ORDER } from './ResultsList'
import fetch from 'isomorphic-unfetch'

type ResultItemRes = {
  sourceId: string
  subtype: string
  suggestion: string
}

type SearchTermResponse = {
  cars: ResultItemRes[]
  suggestions: ResultItemRes[]
  dealers: ResultItemRes[]
  titles: ResultItemRes[]
}

export type ResultItemType = ResultItemRes & {
  _orderIndex: number
  linkUrl: string
}

export type SearchTermState = {
  cars: ResultItemType[]
  suggestions: ResultItemType[]
  dealers: ResultItemType[]
  titles: ResultItemType[]
}

const getResultUrl = (listItem: any, cat: any): string => {
  let resultUrl = null

  if (cat === 'cars') {
    if (listItem && listItem.subtype && listItem.sourceId) {
      const categoriesList = listItem.subtype.split('-') as string[]
      const valuesList = listItem.sourceId.split('-')

      if (categoriesList && valuesList && categoriesList.length === valuesList.length) {
        const httpQueryItems = [] as any[]
        let paramName = null as null | any
        let paramValue = null as null | any

        categoriesList.forEach((item, index) => {
          if (item === 'OTHER_ATTRIBUTES') {
            paramName = parseOtherAttrName(valuesList[index])
            paramValue = 'true'
          } else {
            paramName = parseSuggestParams(
              // @ts-expect-error
              categoriesList[index]
            )
            paramValue = valuesList[index]
          }

          if (paramName) {
            httpQueryItems.push(paramName + '=' + paramValue)
          }
        })

        resultUrl = '/list?' + httpQueryItems.join('&')
      }
    }
  } else if (cat === 'dealers') {
    resultUrl = '/dealer/' + listItem.sourceId
  } else if (cat === 'titles') {
    resultUrl = listItem.sourceId || ''
  }

  return resultUrl
}

const minLengthOfQueryToSearch = 2

let keyPressTimeout = null as null | number

const getDefaultResultState = () => {
  return {
    suggestions: [],
    cars: [],
    dealers: [],
    titles: [],
  } as SearchTermResponse
}

export const HeaderSearch = () => {
  const [results, setResults] = useState(getDefaultResultState())
  const [searchIsCalled, setSearchIsCalled] = useState(false)
  const searchInputElement = useRef<HTMLInputElement>(null as any)
  const searchForm = useRef(null)
  const [storedInputValue, setStoredInputValue] = useState('')

  const resetSearch = () => {
    searchInputElement.current.value = ''
    searchInputElement.current.blur()
    setResults(getDefaultResultState())
  }

  const search = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (keyPressTimeout) {
      window.clearTimeout(keyPressTimeout)
    }

    keyPressTimeout = window.setTimeout(() => {
      if (inputValue && inputValue.length >= minLengthOfQueryToSearch) {
        getResults(inputValue)
        setStoredInputValue(inputValue)
      } else {
        setSearchIsCalled(false)
        setResults(getDefaultResultState())
      }
    }, 200)
  }

  const getResults = async (searchQuery: string) => {
    let resultData = {
      cars: [],
      suggestions: [],
      dealers: [],
      titles: [],
    } as SearchTermResponse

    try {
      const result: SearchTermResponse = await fetch(
        `${API_DOMAIN}/public/suggest?searchTerm=${searchQuery}&lang=${LANGUAGE}`,
        {
          method: 'get',
        }
      ).then(response => response.json())

      resultData = getDefaultResultState()
      setSearchIsCalled(true)
      if (result?.cars || result?.dealers || result?.suggestions || result?.titles) {
        resultData = getDefaultResultState()

        // set dealers and titles directly
        resultData.dealers = result.dealers
        resultData.titles = result.titles

        // cars results parsing
        if (result.cars) {
          // resultDataCar has sourceId (we know filter value) - filter car to results
          resultData.cars = result.cars.filter(item => item.sourceId !== null)
          // resultDataCar has null sourceId (we do not know filter value) - codebook request has to be called
          resultData.suggestions = result.cars.filter(item => item.sourceId === null)
        }

        setResults({ ...resultData })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const parseSuggestion = async (suggestion: string) => {
    searchInputElement.current.value = suggestion

    try {
      // TODO: add api types
      const resultCodebook: { cars: any[] } = await fetch(
        `${API_DOMAIN}/public/suggestCarCodebooksItems?searchTerm=${suggestion}&lang=${LANGUAGE}`,
        {
          method: 'GET',
        }
      ).then(response => response.json())

      const sourceIdArray = [] as string[]
      const resultData = { ...results }
      resultData.suggestions = []

      if (resultCodebook.cars && resultCodebook.cars.length) {
        const combinedResultsSuggestionsArray = [] as any[]
        const combinedResultsSubtypesArray = [] as any[]
        const combinedResultsSourcesArray = [] as any[]

        resultCodebook.cars.forEach(codeBookCarItem => {
          if (codeBookCarItem.sourceId) {
            if (sourceIdArray.indexOf(codeBookCarItem.sourceId) === -1) {
              sourceIdArray.push(codeBookCarItem.sourceId)
              resultData.cars.push(codeBookCarItem)
            }

            combinedResultsSuggestionsArray.push(codeBookCarItem.suggestion)
            combinedResultsSubtypesArray.push(codeBookCarItem.subtype)
            combinedResultsSourcesArray.push(codeBookCarItem.sourceId)
          }
        })

        // set single item combined from all codeBook results found
        // only when more than 1 codeBook result to not create duplicate
        if (resultCodebook.cars.length > 1) {
          resultData.cars.push({
            suggestion: combinedResultsSuggestionsArray.join(' '),
            subtype: combinedResultsSubtypesArray.join('-'),
            sourceId: combinedResultsSourcesArray.join('-'),
          })
        }

        // update results after codebook response
        setResults(resultData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const computedResults = { ...results } as any as SearchTermState
  // add result index to data
  let iterableIndex = 0
  CATEGORIES_ORDER.forEach(category => {
    if (!computedResults[category]) {
      return
    }
    computedResults[category] = computedResults[category].map(categoryResult => ({
      ...categoryResult,
      _orderIndex: iterableIndex++,
      linkUrl: getResultUrl(categoryResult, category),
    }))
  })

  return (
    <div>
      <form
        className='saps-header-search__form'
        ref={searchForm}
        data-component='header-search'
        data-type='search'
        onSubmit={e => e.preventDefault()}
      >
        <input
          className='saps-header-search__query-input'
          type='search'
          name='query'
          autoComplete='off'
          ref={searchInputElement}
          onChange={e => search(e)}
          placeholder={getTranslation('header.search.label')}
        />
      </form>
      <ResultsList
        onEscapeClick={resetSearch}
        results={computedResults}
        suggestionsHandler={parseSuggestion}
        searchIsCalled={searchIsCalled}
        storedInputValue={storedInputValue}
      />
    </div>
  )
}
