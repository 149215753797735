import { Checkbox } from './index'
import { DealerMap_DealersQueryQuery } from './DealerMap.__generated__'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getTranslation } from '../utils/languageUtils'
import { removeFromArray } from '../utils'
import React, { useRef, useState } from 'react'

type Props = {
  dealersList: DealerMap_DealersQueryQuery['dealers']
  viewMode?: 'default' | 'sellCar'
  onDealerSelect?: ((a: any, b: any, c: any) => void) | null
}

const DealerMapBranchesList = (props: Props) => {
  const swiperInstance = useRef(null as null | any)
  const [selectedDealersList, setSelectedDealersList] = useState([] as string[])

  const swipeNext = () => {
    if (!swiperInstance) return
    swiperInstance.current.swiper.slideNext()
  }

  const swipePrev = () => {
    if (!swiperInstance) return
    swiperInstance.current.swiper.slidePrev()
  }

  const breakpointsViewMode = {
    default: {
      sw768: 2,
      sw1024: 3,
      sw1440: 4,
    },
    sellCar: {
      sw768: 2,
      sw1024: 2,
      sw1440: 3,
    },
  }[props.viewMode ?? 'default']

  const dealerCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dealerId = e.target.name
    const selectedDealersListLocal = [...selectedDealersList]

    if (selectedDealersListLocal.indexOf(dealerId) > -1) {
      removeFromArray(selectedDealersListLocal, dealerId)
    } else {
      selectedDealersListLocal.push(dealerId)
    }

    setSelectedDealersList([...selectedDealersListLocal])
    props.onDealerSelect?.(e, null, selectedDealersListLocal)
  }

  const dealerIsSelected = (dealerId: string) => {
    if (selectedDealersList.indexOf(dealerId) > -1) {
      return true
    } else {
      return false
    }
  }

  const RenderedCheckbox = (p: {
    dealerItem: NonNullable<(typeof props)['dealersList']>[number]
  }) => {
    return (
      <Checkbox
        name={`${p.dealerItem.id}`}
        text=''
        checked={dealerIsSelected(p.dealerItem.id)}
        handleChange={e => dealerCheckboxClick(e)}
      />
    )
  }
  const urlSearchQuery = new URLSearchParams(window.location.search)
  let midParam = ''
  if (urlSearchQuery.get('mid') !== null) {
    midParam = '&mid=' + urlSearchQuery.get('mid')
  }

  const openDealerDetail = (id: string) => {
    const url = window.location.origin + `/dealer/${id}/?mid=${id}&dealer=${id}`
    window.open(url, '_blank', undefined)
  }

  const openDealerListDetail = (id: string) => {
    if (midParam !== '') {
      openDealerDetail(id)
    } else {
      const url = window.location.origin + '/list?dealers=' + id
      window.open(url, '_blank', undefined)
    }
  }

  const isDealerMother = (dealer: NonNullable<(typeof props)['dealersList']>[number]) => {
    return dealer.isMother
  }

  return (
    <div className='container'>
      <div className='saps-dealer-items-wrapper'>
        <button
          className='saps-dealer-items__arrow saps-dealer-items__arrow--prev'
          onClick={() => swipeNext()}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' height='16' width='10'>
            <path
              d='M52.5 419L219 252.5 52.5 86q-4.5-4-10.25-4T32 86q-4 4-4 10t4 10l146.5 146.5L32 399q-4 4-4 10t4 10q4 4 9.75 4t10.75-4z'
              fill='#ef7d00'
            />
          </svg>
        </button>
        <Swiper
          ref={swiperInstance}
          key={props.dealersList?.toString()}
          observer={true}
          slidesPerView={1}
          spaceBetween={30}
          // @ts-expect-error
          breakpointsInverse={true}
          breakpoints={{
            768: {
              slidesPerView: breakpointsViewMode.sw768,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: breakpointsViewMode.sw1024,
            },
            1440: {
              slidesPerView: breakpointsViewMode.sw1440,
            },
          }}
        >
          {props.dealersList?.map((dealerItem, index) => (
            <SwiperSlide key={`dealer-${index}`}>
              <div className='saps-dealer-item-box saps-mb-30'>
                {props.viewMode === 'sellCar' && (
                  <div className='saps-dealer-item-box__prefix'>
                    <RenderedCheckbox dealerItem={dealerItem} />
                  </div>
                )}
                <div className='saps-dealer-item-box__content'>
                  <h4 className='saps-p saps-bold saps-color-secondary saps-dealer-item-box__title'>
                    <span
                      onClick={() => openDealerDetail(dealerItem.id)}
                      className='saps-a saps-dealer-item-box__title-a'
                    >
                      {dealerItem.name}
                    </span>
                  </h4>
                  {!isDealerMother(dealerItem) && (
                    <p className='saps-p saps-dealer-item-box__address saps-mb-10'>
                      {dealerItem.address.street}
                      <br />
                      {dealerItem.address.zip}
                      <br />
                      {dealerItem.address.city}
                    </p>
                  )}
                  <div
                    onClick={() => openDealerListDetail(dealerItem.id)}
                    className='saps-button saps-button--link'
                  >
                    <span className='saps-color-secondary saps-mr-10'>
                      {dealerItem.carsCount} {getTranslation('dealerMapBranchesList.carsInList')}
                    </span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 256 512'
                      height='13'
                      width='8'
                    >
                      <path
                        d='M52.5 419L219 252.5 52.5 86q-4.5-4-10.25-4T32 86q-4 4-4 10t4 10l146.5 146.5L32 399q-4 4-4 10t4 10q4 4 9.75 4t10.75-4z'
                        fill='#ef7d00'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className='saps-dealer-items__arrow saps-dealer-items__arrow--next'
          onClick={() => swipePrev()}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' height='16' width='10'>
            <path
              d='M52.5 419L219 252.5 52.5 86q-4.5-4-10.25-4T32 86q-4 4-4 10t4 10l146.5 146.5L32 399q-4 4-4 10t4 10q4 4 9.75 4t10.75-4z'
              fill='#ef7d00'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default DealerMapBranchesList
