// import BrowserNotCompatibleLightbox from '../common/common-layout/BrowserNotCompatibleLightbox'
import { CULTURE } from '../splus-lib/utils/languageUtils'
import { Configuration } from '../../kentico-types/configuration'
import { getContent } from '../../lib/kenticoClient'
import { getPlaceholdersFromLS } from '../splus-lib/utils/globalUtils'
import { gtmDataLayerPush, gtmLeid } from '../splus-lib/utils/googleTagManager/utils'
import { useComponentDidMount } from '../splus-lib/utils/hooks/hooks'
import Footer from '../common/common-layout/Footer'
import Header from '../common/common-layout/Header'
import LiveChat from '../common/common-layout/LiveChat'
import React, { useEffect, useState } from 'react'

type Props = {
  children?: React.ReactNode
  footerTopOverlay: boolean
  hasBackground: boolean
}

const PageWrapper = ({ children, footerTopOverlay, hasBackground }: Props) => {
  const [kenticoData, setKenticoData] = useState<Configuration | null>(null)
  const pageHasContainer = true

  const getPageBgrClass = () => {
    const noBgrClass = 'controls-visible yui3-skin-sam signed-out public-page site'
    const bgrClass = 'controls-visible yui3-skin-sam signed-out public-page site saps-body-has-bgr'

    if (hasBackground) {
      return bgrClass
    }
    return noBgrClass
  }

  useComponentDidMount(() => {
    document.body.className = ''
    document.body.className = getPageBgrClass()
  })

  const showLiveChat = () => {
    const d = new Date()
    return d.getHours() >= 8 && d.getHours() <= 19 && d.getDay() >= 1 && d.getDay() <= 5
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const kenticoData = await getContent('configuration')
        setKenticoData(kenticoData.content)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className='saps-page-wrapper' id='wrapper'>
        <Header content={kenticoData ?? undefined} />
        <div
          className={
            pageHasContainer ? 'saps-page-content saps-page-container' : 'saps-page-content'
          }
        >
          <div className='skoda-plus-layout-template' id='main-content' role='main'>
            {children}
          </div>
        </div>
        <Footer topOverlay={footerTopOverlay} content={kenticoData ?? undefined} />
      </div>
      {showLiveChat() && <LiveChat />}
      {/*<BrowserNotCompatibleLightbox />*/}
    </React.Fragment>
  )
}
export default PageWrapper
