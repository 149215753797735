import { ReactComponent as IconDropdown } from './svg/dropdown.svg'
import { capitalize } from '../../utils'
import DropdownOptionsWrapper from './DropdownOptionsWrapper'
import React, { useEffect, useState } from 'react'

type Props = {
  name: string
  aCSSClass?: string
  customCSSClass?: string
  labelForEmpty?: string
  options: any[]
  selectedOptionID: string | number | null
  handleChange: (data: {
    target: {
      name: string
      value: string | null
    }
  }) => void
  isValid?: boolean
  showNumberOfItems?: boolean
  minContentDropdownWidth?: number
  labelForceOverride?: string
  canDeselect?: boolean
}

const InputRadioDropdown = (props: Props) => {
  const {
    name,
    options,
    labelForEmpty = '',
    labelForceOverride,
    customCSSClass = '',
    handleChange,
    selectedOptionID,
    showNumberOfItems = true,
    isValid = true,
    minContentDropdownWidth,
    canDeselect = true,
    aCSSClass = '',
  } = props

  const [dropdownOpenState, setDropdownOpenState] = useState(false)
  // TODO: add useRef -> current
  const [wrapperRef, setWrapperRef] = useState<null | HTMLDivElement>(null)

  useEffect(() => {
    const handleClickAnywhere = (e: MouseEvent) => {
      // close dropdown if clicked outside of it
      if (wrapperRef && !wrapperRef?.contains(e.target as Node)) {
        setDropdownOpenState(false)
      }
    }

    document.addEventListener('click', handleClickAnywhere)
    return () => document.removeEventListener('click', handleClickAnywhere)
  }, [wrapperRef])

  const toggleDropdownOpenState = () =>
    options.length === 0 ? setDropdownOpenState(false) : setDropdownOpenState(!dropdownOpenState)

  const selectedOption = options.find(
    option => option.id?.toString() === selectedOptionID?.toString()
  )
  const label = selectedOption
    ? selectedOption.name || selectedOption.value || selectedOption.id
    : labelForEmpty

  const finalLabel = labelForceOverride
    ? labelForceOverride
    : options.length === 0
      ? capitalize(labelForEmpty)
      : capitalize(label)

  return (
    <div className={`saps-inputlist-dropdown ${customCSSClass}`} ref={setWrapperRef}>
      <a
        style={!isValid ? styles.isNotValid : {}}
        className={`saps-inputlist-dropdown__toggle saps-basic-filter--mobile ${aCSSClass}`}
        onClick={() => toggleDropdownOpenState()}
      >
        <span className='saps-inputlist-dropdown__toggle-text saps-inputlist-dropdown__toggle-text--radio'>
          {finalLabel}
        </span>
        {options.length !== 0 && (
          <span className='saps-inputlist-dropdown__toggle-icon'>
            <IconDropdown />
          </span>
        )}
      </a>

      <DropdownOptionsWrapper
        key={options.length}
        optionsLength={options.length}
        handleChange={({ focusedOptionIndex }) => {
          toggleDropdownOpenState()
          // TODO: shitty hack with "custom" event instead of real one
          handleChange({
            target: {
              name,
              value: options[focusedOptionIndex].id,
            },
          })
        }}
        className={`saps-inputlist-dropdown__list-wrapper ${
          dropdownOpenState && options.length !== 0 ? ' saps-dialog-active ' : ''
        }`}
        isDropdownActive={dropdownOpenState}
        style={minContentDropdownWidth ? { width: minContentDropdownWidth } : {}}
      >
        {({ focusedOptionIndex }, optionsRefs) => (
          <ul className='saps-inputlist-dropdown__list'>
            {options.map((option, index) => (
              <li
                ref={ref => (optionsRefs[index] = ref)}
                style={focusedOptionIndex === index ? styles.lightBackground : {}}
                className='saps-inputlist-dropdown__list-item'
                key={`${option.name}-${index}`}
              >
                <label className='saps-inputlist-dropdown__list-item-label'>
                  <input
                    type='radio'
                    name={name}
                    className='saps-input-checkbox'
                    value={option.id}
                    checked={selectedOptionID === option.id}
                    onClick={() => {
                      // TODO: shitty hack with "custom" event instead of real one
                      canDeselect &&
                        selectedOptionID === option.id &&
                        handleChange({
                          target: {
                            name,
                            value: null,
                          },
                        })
                      toggleDropdownOpenState()
                    }}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                  <span className='saps-checkbox-title'>
                    {capitalize(option.name) || capitalize(option.value)}{' '}
                    {showNumberOfItems && `(${option.carsCount ? option.carsCount : '0'})`}
                  </span>
                </label>
              </li>
            ))}
          </ul>
        )}
      </DropdownOptionsWrapper>
    </div>
  )
}

const styles = {
  lightBackground: { background: '#EEE' },
  isNotValid: { color: '#cc0000', borderColor: '#cc0000' },
}

export default React.memo(InputRadioDropdown)
