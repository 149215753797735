import { ReactComponent as Checkmark } from './svg/checkmark.svg'
import { Portal } from '../index'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import React from 'react'

type Props = {
  name: string
  checked: boolean
  hex: string
  handleClick: (e: boolean) => void
  className?: string
}
const InputCheckboxColor = ({ name, checked = false, hex, handleClick }: Props) => {
  const styles = {
    colorCheckbox:
      hex === '#fff' || hex === 'transparent'
        ? { backgroundColor: hex, border: '1px solid #929292' }
        : { backgroundColor: hex },
  }

  return (
    <div
      className='saps-input-checkbox-color__wrapper'
      data-title={name}
      onClick={() => handleClick(!checked)}
    >
      <a className={`anchor-element-${name}`}>
        <div
          data-tip={name}
          className={`saps-input-checkbox-color__circle ${
            checked && 'saps-input-checkbox-color__circle_checked'
          }`}
          style={styles.colorCheckbox}
        />
      </a>

      {checked && (
        <div className='saps-input-checkbox-color__check-wrapper'>
          <Checkmark className='saps-input-checkbox-color__check-icon' />
        </div>
      )}

      <ReactTooltip anchorSelect={`.anchor-element-${name}`} place='top' clickable>
        <div>{name}</div>
      </ReactTooltip>
    </div>
  )
}

export default InputCheckboxColor
