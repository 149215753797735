import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class OSkodaPlus extends ContentItem {
  public skodaplusTitle: Elements.RichTextElement
  public skodaplusDescriptionAbovePicture: Elements.RichTextElement
  public skodaplusPicture: Elements.AssetsElement
  public skodaplusVideoUrl: Elements.TextElement
  public skodaplusDescriptionBelowPicture: Elements.RichTextElement
  public advantagesTitle: Elements.RichTextElement
  public advantagesItems: Elements.RichTextElement
  public programs: Elements.RichTextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'skodaplus_title') {
          return 'skodaplusTitle'
        }
        if (elementName === 'skodaplus_description_above_picture') {
          return 'skodaplusDescriptionAbovePicture'
        }
        if (elementName === 'skodaplus_picture') {
          return 'skodaplusPicture'
        }
        if (elementName === 'skodaplus_video_url') {
          return 'skodaplusVideoUrl'
        }
        if (elementName === 'skodaplus_description_below_picture') {
          return 'skodaplusDescriptionBelowPicture'
        }
        if (elementName === 'advantages_title') {
          return 'advantagesTitle'
        }
        if (elementName === 'advantages_items') {
          return 'advantagesItems'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
