import { SPLink } from '../../../components/SPLink'
import Button from '../Button'

import React from 'react'

type Props = {
  title?: string
  url?: string
  children?: any
  disabled?: boolean
  greyButton?: boolean
  fullWidth?: boolean
  className?: string
  style?: React.CSSProperties
  onClick?: (e: any) => void
}

const LinkButton = ({ onClick, url, title, ...props }: Props) => {
  if (props.disabled) {
    return <Button {...props}>{props.children}</Button>
  }

  return (
    <SPLink href={url!} title={title} className='saps-linkButton' onClick={onClick}>
      <Button {...props}>{props.children}</Button>
    </SPLink>
  )
}

export default LinkButton
