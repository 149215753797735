import { getOffsetToScrollOnTopOfPage } from '../browserScrollAPI'
import { useEffect, useState } from 'react'

const PRE_REFETCH_OFFSET_HEIGHT = 1000

const useInfiniteScroll = (callback: any) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      // @ts-expect-error
      const footerHeight = document.querySelector('.saps-footer').offsetHeight
      const windowViewHeight = window.innerHeight
      const offsetToScrollOnTopOfPage = getOffsetToScrollOnTopOfPage()
      const footerTopOffset = document.body.offsetHeight - footerHeight
      if (isFetching) {
        return
      }
      if (
        windowViewHeight + offsetToScrollOnTopOfPage <=
        footerTopOffset - PRE_REFETCH_OFFSET_HEIGHT
      ) {
        return
      }
      setIsFetching(true)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFetching])

  useEffect(() => {
    if (!isFetching) return
    callback()
  }, [isFetching])

  return [isFetching, setIsFetching] as const
}

export default useInfiniteScroll
