import React, { createContext, useContext, useEffect, useState } from 'react'

const useFooterScrollSpy = () => {
  const [isFooter, setIsFooter] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
        document.documentElement.offsetHeight -
          (document.querySelector('.saps-footer') as HTMLElement).offsetHeight
      ) {
        setIsFooter(false)
      } else {
        setIsFooter(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return {
    isFooter,
  }
}

export const ScrollSpyContext = createContext({})

type Props = {
  children: React.ReactNode
}
export const ScrollSpyProvider = ({ children }: Props) => (
  <ScrollSpyContext.Provider value={useFooterScrollSpy()}>{children}</ScrollSpyContext.Provider>
)

export const useScrollSpy = () => useContext(ScrollSpyContext)
