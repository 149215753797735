import React from 'react'

type Props = {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const Loading = (props: Props) => (
  <div style={{ textAlign: 'center', ...(props.style ?? {}) }}>
    <div className='saps-car-filter__filter-title saps-mb-20'>
      <b>{props.children}</b>
    </div>
    <div className='lds-dual-ring' />
  </div>
)

export default Loading
