/**
 * @generated SignedSource<<4303ce0e581d4e53cff1b47c4373bc66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturesFilter_data$data = {
  readonly equipmentItems: ReadonlyArray<{
    readonly category: {
      readonly id: string;
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "FeaturesFilter_data";
};
export type FeaturesFilter_data$key = {
  readonly " $data"?: FeaturesFilter_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturesFilter_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "lang",
      "variableName": "lang"
    }
  ],
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeaturesFilter_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarEquipmentItem",
      "kind": "LinkedField",
      "name": "equipmentItems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CarEquipmentCategory",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0633c00cce1d48cfa42f4cf2eb46b7f4";

export default node;
