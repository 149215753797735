import { createPortal } from 'react-dom'
import { process_browser } from '../../components/withNoSSR'
import usePortal from '../utils/hooks/usePortal'

type Props = {
  id: any
  children: React.ReactNode
}

const Portal = (props: Props) => {
  const target = usePortal(props.id)
  return createPortal(props.children, target)
}

const PortalNoSSR = (props: Parameters<typeof Portal>[0]) => {
  if (!process_browser) return <div />
  return <Portal {...props} />
}

export default PortalNoSSR
