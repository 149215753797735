import { ReactComponent as IconInfo } from './assets/info-lb.svg'
import { ReactComponent as IconPlay } from './assets/play-lb.svg'
import { ParamLightbox, Portal } from './index'
import { useComponentDidMount } from '../utils/hooks/hooks'
import React, { useState } from 'react'

type Equipment = {
  images: string[]
  key: string
  text: string
  title: string
  videoUrl: string
  parentCategory: string
}

type Props = {
  equipmentId: string | null
  smallSize?: boolean
  equipmentData: any
}
const ShowInfoLightBox = (props: Props) => {
  const [showLightBox, setShowLightBox] = useState(false)
  const [lightBoxData, setLightBoxData] = useState<Equipment | null>(null)

  useComponentDidMount(() => {
    const data = props.equipmentData
    if (data) {
      // @ts-expect-error
      const filteredData = data.find(({ key }) => key === props.equipmentId)
      if (filteredData) {
        setLightBoxData(filteredData)
      }
    }
  })

  return (
    <div style={{ marginLeft: '5px' }}>
      <a style={{ cursor: 'pointer' }} onClick={() => setShowLightBox(true)}>
        {lightBoxData && lightBoxData?.videoUrl ? (
          <IconPlay className='d-block' />
        ) : (
          <IconInfo className='d-block' />
        )}
      </a>
      {showLightBox && (
        <Portal id='lightbox'>
          <ParamLightbox
            smallSize={props.smallSize}
            images={lightBoxData?.images}
            videoUrl={lightBoxData?.videoUrl}
            title={lightBoxData?.title}
            text={lightBoxData?.text}
            handleClose={() => setShowLightBox(false)}
            // TODO: remove this hack, i dont know where to fetch additional property
            parentCategory={'innerEquipment'}
          />
        </Portal>
      )}
    </div>
  )
}

export default ShowInfoLightBox
