import React from 'react'

type Props = {
  value?: string
  text: string
  clickCallback?: () => void
  customCSSClass?: () => void
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const RemovableTag = (props: Props) => (
  <div className={`saps-removable-tag ${props.customCSSClass ?? ''}`}>
    <span className='saps-removable-tag__text'>{props.text}</span>
    <button
      type='button'
      className='saps-removable-tag__remove'
      value={props.value}
      onClick={props.handleClick}
    >
      &times;
    </button>
  </div>
)

export default RemovableTag
