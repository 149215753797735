import { Configuration } from '../../../kentico-types/configuration'
import { HeaderSearch } from '../../widget-header-search'
import { SPLink } from '../../components/SPLink'
import { useComponentDidMount } from '../../splus-lib/utils/hooks/hooks'
import MainMenu from './MainMenu'
import React, { useState } from 'react'
import TopMenu from './TopMenu'

type Props = {
  content?: Configuration
}
const Header = (props: Props) => {
  const [logoUrl, setLogoUrl] = useState('/')
  const [mid, setMid] = useState('')
  const [menuOpened, setMenuOpened] = useState(false)

  useComponentDidMount(() => {
    const url = window.location.href
    const urlObject = new URL(window.location.href)
    const seqUrlIndex = url.indexOf('mid')

    if (seqUrlIndex !== -1) {
      setMid(url.substr(seqUrlIndex + 4))
      setLogoUrl(`${urlObject.pathname}?${url.substr(seqUrlIndex)}`)
    }
  })

  const toggleMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setMenuOpened(!menuOpened)
  }

  const closeMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setMenuOpened(false)
  }

  return (
    <React.Fragment>
      <div className='saps-header'>
        <div className='saps-page-container saps-h100 container-fluid'>
          <div className='row justify-content-between align-items-center saps-h100'>
            <div className='col-auto'>
              <SPLink href={logoUrl}>
                <img
                  src='/static/SkodaPlus-logo.png'
                  alt='Škoda Plus'
                  className='saps-header__logo'
                />
              </SPLink>
            </div>
            <div className='col-auto'>
              <div className='d-flex align-items-center'>
                <div className='saps-top-menu-portlet-wrapper'>
                  <TopMenu mid={mid} />
                </div>
                {mid === '' && (
                  <React.Fragment>
                    <div className='saps-header-search-portlet-wrapper'>
                      <div className='saps-header-search'>
                        <button
                          className='saps-header-search__toggle js-toggle-header-search'
                          type='button'
                        >
                          <svg
                            width='30'
                            height='30'
                            viewBox='0 0 30 30'
                            xmlns='http://www.w3.org/2000/svg'
                            className='saps-header-search__toggle-icon'
                          >
                            <path
                              d='M5.43 11.84a6.42 6.42 0 1 1 10.97 4.49l-.04.03-.03.04a6.42 6.42 0 0
                              1-10.9-4.56m20.14 12.72l-7.71-7.71A7.85 7.85 0 1 0 4 11.84a7.85 7.85
                              0 0 0 12.85 6.02l7.7 7.7a.71.71 0 1 0 1.02-1'
                              className='saps-header-search__toggle-path'
                            />
                          </svg>
                        </button>
                        <div className='saps-header-results-wrapper'>
                          <button
                            type='button'
                            className='saps-header-search__close js-close-header-search'
                          />
                          <div className='saps-header-search__form-wrapper'>
                            <HeaderSearch />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='saps-header-menu-toggle-wrapper'>
                      <a href='#' className='saps-menu-toggle js-menu-toggle' onClick={toggleMenu}>
                        <span className='saps-menu-toggle__bars' />
                        <svg
                          width='30'
                          height='30'
                          viewBox='0 0 30 30'
                          xmlns='http://www.w3.org/2000/svg'
                          className='saps-menu-toggle__icon'
                        >
                          <path
                            d='M4 9.79V4h5.79v5.79H4zm8.1 0V4h5.8v5.79h-5.8zm8.11 0V4H26v5.79h-5.79zM4
                            17.89v-5.78h5.79v5.79H4zm8.1 0v-5.78h5.8v5.79h-5.8zm8.11 0v-5.78H26v5.79h-5.79zM4
                            26v-5.79h5.79V26H4zm8.1 0v-5.79h5.8V26h-5.8zm8.11 0v-5.79H26V26h-5.79z'
                            className='saps-menu-toggle__icon-path'
                            fillRule='evenodd'
                          />
                        </svg>
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='saps-main-menu-portlet-wrapper'>
        <MainMenu opened={menuOpened} close={closeMenu} content={props.content} />
      </div>
    </React.Fragment>
  )
}
export default Header
