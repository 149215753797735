import React from 'react'
import ReactMarkdownWithHtml from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const replaceCharacters = (markdown: string) => {
  return markdown
    .replace(/&amp;/g, '\u0026')
    .replace(/&nbsp;/g, '\u00A0')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
}

const markdownToHtml = (markdown: string) => (
  <ReactMarkdownWithHtml rehypePlugins={[rehypeRaw]} skipHtml={false}>
    {replaceCharacters(markdown)}
  </ReactMarkdownWithHtml>
)

export default markdownToHtml
