import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Contact extends ContentItem {
  public contactText: Elements.RichTextElement
  public contactLink: Elements.RichTextElement
  public contactPicture: Elements.AssetsElement
  public contactInfo: Elements.LinkedItemsElement<ContentItem>
  public addressAddress: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'contact_text') {
          return 'contactText'
        }
        if (elementName === 'contact_link') {
          return 'contactLink'
        }
        if (elementName === 'contact_picture') {
          return 'contactPicture'
        }
        if (elementName === 'contact_info') {
          return 'contactInfo'
        }
        if (elementName === 'address_address') {
          return 'addressAddress'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
