import { DemoCarTypeInput } from '../../../types.__generated__'
import { Params } from '../../../widget-filter/CarFilterStateContext'
import { isNumeric } from '../globalUtils'
import { path } from 'ramda'
import isEmail from 'validator/lib/isEmail'

export const transformToNumber = (value: string | number | null, defaultValue?: null | number) => {
  const transformedValue = value && parseInt(value as string)

  return isNumeric(transformedValue as string) ? transformedValue : defaultValue
}

/*  @function to sort data from db alphabetically
 *  @param {List{id,name}} list - list to sort [{id: '0', name: 'example}]
 *  @return sorted array
 */
export const sortByNestedName = <T extends { name?: string | null | undefined }>(
  list: T[]
): T[] => {
  return list.slice().sort((a, b) => {
    const nameA = a.name?.toUpperCase() ?? '' // ignore upper and lowercase
    const nameB = b.name?.toUpperCase() ?? '' // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
}

/*  @function to sort data from db alphabetically
 *  @param {List{id}} list - list to sort [{id: '0'}]
 *  @return sorted array
 */
export const sortByNestedID = <T extends { id: string }>(list: T[]): T[] => {
  return list.slice().sort((a, b) => {
    const A = a.id.toUpperCase() // ignore upper and lowercase
    const B = b.id.toUpperCase() // ignore upper and lowercase
    if (A < B) {
      return -1
    }
    if (A > B) {
      return 1
    }

    // names must be equal
    return 0
  })
}

/*  @function to sort nested array of object data from alphabetically
 *  @param {List{id}} list - list to sort [{id: '0'}]
 *  @return sorted array
 */
export const sortBySubNestedKey = <T extends Record<string, any>>(list: T[], key: string[]): T[] =>
  list.slice().sort((a, b) => {
    // ignore upper and lowercase
    const nameA = (path<string>(key, a) as string)?.toUpperCase() ?? ''
    const nameB = (path<string>(key, b) as string)?.toUpperCase() ?? ''

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })

export const orderArrayOfObjects = <T extends { name: string; id: any }>(
  list: T[],
  preferredItems: string[] = []
): T[] => {
  let nameA
  let nameB

  const sortedArr = list.slice().sort((a, b) => {
    nameA = a.name.toLowerCase() // ignore upper and lowercase
    nameB = b.name.toLowerCase() // ignore upper and lowercase

    // sorting preferred items
    if (preferredItems.indexOf(a.id) > -1 && preferredItems.indexOf(b.id) > -1) {
      if (preferredItems.indexOf(a.id) > preferredItems.indexOf(b.id)) {
        return 1
      }
      if (preferredItems.indexOf(a.id) < preferredItems.indexOf(b.id)) {
        return -1
      }
    } else if (preferredItems.indexOf(a.id) > -1 && preferredItems.indexOf(b.id) === -1) {
      return -1
    } else if (preferredItems.indexOf(a.id) === -1 && preferredItems.indexOf(b.id) > -1) {
      return 1
    }

    // sorting list items
    else {
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
    }

    return 0
  })

  return sortedArr
}

export const sanitizeValue = <T, U>(
  value: undefined | null | T,
  defaultIfValueEmpty: U | undefined = undefined
): T | U | undefined => {
  if (value === undefined || value === null) {
    return defaultIfValueEmpty
  }

  return value
}

export const parseStringifiedBoolToBool = (
  stringifyBool: string | null,
  defaultValue?: boolean
) => {
  if (stringifyBool === 'false') {
    return false
  } else if (stringifyBool === 'true') {
    return true
  }
  if (defaultValue !== undefined) {
    return defaultValue
  }
  return null
}

export const sanitizeArray = <T>(
  params: string[] | 'null',
  defaultIfArrEmptyValue: null | T = null
): string[] | T | null => {
  // check if array
  let paramsTransform = undefined
  if (
    params === undefined ||
    params === null ||
    params === 'null' ||
    (Array.isArray(params) && params.length === 1 && params[0] === 'null')
  ) {
    paramsTransform = defaultIfArrEmptyValue
    return paramsTransform
  } else if (!Array.isArray(params)) {
    paramsTransform = [params]
  } else {
    paramsTransform = params
  }
  const filteredEmptyStrings = paramsTransform.filter(item => item !== '')

  return filteredEmptyStrings.length === 0 ? defaultIfArrEmptyValue : filteredEmptyStrings
}

const getDemoCarTypes = (
  isUsed: boolean | null,
  isSkodaPlus: boolean | null,
  isOneYearCar: boolean | null,
  isDemo: boolean | null
) => {
  if (isUsed || isSkodaPlus || isOneYearCar) {
    if (isDemo) {
      return [DemoCarTypeInput.Empty, DemoCarTypeInput.ForSale, DemoCarTypeInput.OnRequest]
    } else {
      return [DemoCarTypeInput.Empty, DemoCarTypeInput.ForSale]
    }
  } else {
    if (isDemo) {
      return [DemoCarTypeInput.OnRequest]
    } else {
      return []
    }
  }
}

// type EnterType = Record<
//   string,
//   null | {
//     min: null | undefined | number | ''
//     max: null | undefined | number | ''
//   }
// >

// type ReturnType<T extends EnterType> = {
//   [Key in T]: {
//     min: null | undefined | number | ''
//     max: null | undefined | number | ''
//   }
// }

// const getBasicMinMaxFilterValue = <T extends EnterType>(
//   filterVal: T | null
// ): ReturnType<T> | {} => {
//   if (!filterVal) {
//     return {}
//   }

//   let collector: Partial<ReturnType<T>> = {}
//   Object.entries(filterVal).forEach(([k, v]) => {
//     if (!v) {
//       return
//     }
//     // @ts-expect-error
//     collector[`${k}`] = v
//   })
//   return collector
// }

const includeValue = (
  val: null | { min: undefined | null | '' | number; max: undefined | null | '' | number }
): boolean => {
  return !!val && (!!val?.min || !!val?.max)
}

const handleIncludeValue = (obj: Object | null, includeValue: boolean, label: string) => {
  return !includeValue
    ? {}
    : {
        [`${label}`]: { ...obj },
      }
}

export const basicFilter = ({
  make,
  model,
  priceRange,
  mileageRange,
  fuelTypes,
  transmissionTypes,
  regions,
  firstRegistration,
  isSkodaPlus,
  isOneYearCar,
  bodyTypes,
  carsOrderBy,
  dealers,
  colors,
  equipmentItems,
  metallicPaint,
  hasServiceBook,
  doorsCountRange,
  seatsCountRange,
  engineCapacity,
  enginePower,
  isUsed,
  firstOwner,
  skofinOffers,
  isDemo,
  instalmentRange,
}: Params) => ({
  make: sanitizeArray(make, null),
  model: sanitizeArray(model, null),
  ...{ ...handleIncludeValue(priceRange, includeValue(priceRange), 'priceRange') },
  ...{ ...handleIncludeValue(mileageRange, includeValue(mileageRange), 'mileageRange') },
  ...{ ...handleIncludeValue(engineCapacity, includeValue(engineCapacity), 'engineCapacity') },
  ...{ ...handleIncludeValue(enginePower, includeValue(enginePower), 'enginePower') },
  ...{ ...handleIncludeValue(doorsCountRange, includeValue(doorsCountRange), 'doorsCountRange') },
  ...{ ...handleIncludeValue(seatsCountRange, includeValue(seatsCountRange), 'seatsCountRange') },
  ...{
    ...handleIncludeValue(firstRegistration, includeValue(firstRegistration), 'firstRegistration'),
  },
  // priceRange: {
  //   min: priceRange ? transformToNumber(priceRange.min, 0) : null,
  //   max: priceRange ? transformToNumber(priceRange.max, null) : null,
  // },
  // mileageRange: {
  //   min: mileageRange ? transformToNumber(mileageRange.min, 0) : null,
  //   max: mileageRange ? transformToNumber(mileageRange.max, null) : null,
  // },
  // engineCapacity: {
  //   min: engineCapacity ? transformToNumber(engineCapacity.min, 0) : null,
  //   max: engineCapacity ? transformToNumber(engineCapacity.max, null) : null,
  // },
  // enginePower: {
  //   min: enginePower ? transformToNumber(enginePower.min, 0) : null,
  //   max: enginePower ? transformToNumber(enginePower.max, null) : null,
  // },
  // doorsCountRange: {
  //   min: doorsCountRange ? transformToNumber(doorsCountRange.min, 0) : null,
  //   max: doorsCountRange ? transformToNumber(doorsCountRange.max, null) : null,
  // },
  // seatsCountRange: {
  //   min: seatsCountRange ? transformToNumber(seatsCountRange.min, 0) : null,
  //   max: seatsCountRange ? transformToNumber(seatsCountRange.max, null) : null,
  // },
  // firstRegistration: {
  //   min: firstRegistration ? transformToNumber(firstRegistration.min, 0) : null,
  //   max: firstRegistration ? transformToNumber(firstRegistration.max, null) : null,
  // },
  dealers: sanitizeArray(dealers, null),
  colors: sanitizeArray(colors, null),
  equipmentItems: sanitizeArray(equipmentItems, null),
  metallicPaint,
  hasServiceBook,
  transmissionTypes: sanitizeArray(transmissionTypes, null),
  skodaPlus: isSkodaPlus,
  oneYearCar: isOneYearCar,
  usedCar: isUsed ? true : null,
  regions: sanitizeArray(regions, null),
  fuelTypes: sanitizeArray(fuelTypes, null),
  bodyTypes: sanitizeArray(bodyTypes, null),
  carsOrderBy,
  firstOwner,
  skofinOffers: sanitizeArray(skofinOffers, null),
  demoCarTypes: getDemoCarTypes(isUsed, isSkodaPlus, isOneYearCar, isDemo),
  instalmentRange: {
    min: instalmentRange ? transformToNumber(instalmentRange.min, 0) : null,
    max: instalmentRange ? transformToNumber(instalmentRange.max, null) : null,
  },
})

export const czechMapNuc = [
  {
    id: 'CZ031',
    name: 'Jihočeský kraj',
  },
  {
    id: 'CZ064',
    name: 'Jihomoravský kraj',
  },
  {
    id: 'CZ041',
    name: 'Karlovarský kraj',
  },
  {
    id: 'CZ052',
    name: 'Královéhradecký kraj',
  },
  {
    id: 'CZ051',
    name: 'Liberecký kraj',
  },
  {
    id: 'CZ080',
    name: 'Moravskoslezský kraj',
  },
  {
    id: 'CZ071',
    name: 'Olomoucký kraj',
  },
  {
    id: 'CZ053',
    name: 'Pardubický kraj',
  },
  {
    id: 'CZ072',
    name: 'Zlínský kraj',
  },
  {
    id: 'CZ032',
    name: 'Plzeňský kraj',
  },
  {
    id: 'CZ010',
    name: 'Hlavní město Praha',
  },
  {
    id: 'CZ020',
    name: 'Středočeský kraj',
  },
  {
    id: 'CZ042',
    name: 'Ústecký kraj',
  },
  {
    id: 'CZ063',
    name: 'Kraj Vysočina',
  },
]

export const slovakMapNuc = [
  {
    id: 'SK010',
    name: 'Bratislavský kraj',
  },
  {
    id: 'SK021',
    name: 'Trnavský kraj',
  },
  {
    id: 'SK023',
    name: 'Nitransky kraj',
  },
  {
    id: 'SK022',
    name: 'Trenčínsky kraj',
  },
  {
    id: 'SK032',
    name: 'Banskobystrický kraj',
  },
  {
    id: 'SK042',
    name: 'Košický kraj',
  },
  {
    id: 'SK041',
    name: 'Prešovský kraj',
  },
  {
    id: 'SK031',
    name: 'Žilinský kraj',
  },
]

// returns name by id from list [{id, name}]
export const getNameById = <ID, Name, T extends { name?: Name | null | undefined; id: ID }>(
  list: T[],
  id: ID
): Name | null | undefined => {
  if (list?.length) {
    const foundItem = list.find(item => {
      return item.id === id
    })
    if (foundItem) {
      return foundItem.name
    }
    return null
  }

  return null
}

const paramsTable = {
  MAKE: 'make',
  MODEL: 'model',
  FUEL_TYPE: 'fuelTypes',
  BODY_TYPE: 'bodyTypes',
  COLOR: 'colors',
  TRANSMISSION: 'transmissionTypes',
  EQUIPMENT: 'equipmentItems',
  EQUIPMENT_LEVEL: 'equipmentLevels',
  OTHER_ATTRIBUTES: '',
}

export const parseSuggestParams = (suggestParam: keyof typeof paramsTable) => {
  return paramsTable[suggestParam] || null
}

export const parseOtherAttrName = (attributeString: string) => {
  const firstUpperCased = attributeString
    .toLowerCase()
    .split('_')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join('')

  return firstUpperCased.charAt(0).toLowerCase() + firstUpperCased.slice(1)
}

/**
 * Validates input
 *
 * @param {value} input value
 * @param {[string]} validation Available validations:
 */
export type ValidationOption = 'required' | 'phone' | 'email' | 'longText' | 'selected'
export const validateInput = (value: string, validation: ValidationOption[]) => {
  let toReturn = true

  if (validation && Array.isArray(validation)) {
    validation.forEach(validationCategory => {
      if (validationCategory === 'required' && value.length === 0) {
        toReturn = false
      }
      if (validationCategory === 'phone' && (value.length < 9 || !/^\+?[\d\s]*$/.test(value))) {
        toReturn = false
      }
      if (validationCategory === 'email' && (value.length < 5 || isEmail(value) === false)) {
        toReturn = false
      }
    })
  }
  return toReturn
}

/**
 *
 * Returns a new array of GPS coordinates object sorted by distance from center
 *
 * @param {List} originalList - list of Objects with gps.latitude and gps.longitude params
 * @param {google LatLng} center - google maps LatLang center point
 * @googleInstance {google object instance}
 */
export const sortGpsListByDistance = <
  T extends {
    gps:
      | {
          latitude?: string | null | undefined
          longitude?: string | null | undefined
        }
      | null
      | undefined
  },
>(
  originalList: T[],
  center: any,
  googleInstance: any
): T[] => {
  let dealerLatLang = {}
  let distance = 0

  // @ts-expect-error
  return originalList
    .map(item => {
      // adding distance param to originalList item
      dealerLatLang = new googleInstance.maps.LatLng(item.gps?.latitude, item.gps?.longitude)
      if (!dealerLatLang) return
      if (Object.keys(dealerLatLang).length === 0) return
      if (!center) return
      distance = googleInstance.maps.geometry.spherical?.computeDistanceBetween(
        dealerLatLang,
        center
      )
      return {
        ...item,
        distance,
      }
    })
    .filter(Boolean)
    .sort((a, b) => {
      return a!.distance - b!.distance
    })
}
