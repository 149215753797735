import { Checkbox, InputCheckboxColor } from '../index'
import { ColorsFilter_DataFragment } from './ColorsFilter.__generated__'
import { capitalize } from '../../utils'
import { createFragmentContainer, graphql } from 'react-relay'
import { getTranslation } from '../../utils/languageUtils'
import React from 'react'

const colorHex = {
  color_1: '#fff',
  color_2: '#000',
  color_3: '#0000ff',
  color_4: '#008000',
  color_5: '#808080',
  color_6: '#c0c0c0',
  color_7: '#ff0000',
  color_8: '#ffff00',
  color_9: 'brown',
  color_10: '#ee82ee',
  color_11: 'gold',
  color_12: '#05416f',
  color_13: '#b19780',
  color_14: 'orange',
  color_15: 'transparent',
}

type Props = {
  data: ColorsFilter_DataFragment | null
  colors: string[]
  metallicPaint: boolean | null
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCustomCheckboxChange: (name: 'colors', id: string, checked: boolean) => void
}

const ColorsFilter = (props: Props) => {
  return (
    <div className='saps-car-detail-features'>
      <hr className='saps-hr saps-mb-30' />
      <div className='saps-mb-50'>
        <div className='saps-mb-40 d-flex'>
          <div
            className='saps-car-detail-features__title'
            style={{
              marginRight: 'auto',
            }}
          >
            {getTranslation('color.label')}
          </div>
          <Checkbox
            customCSSClass='saps-car-detail-features__value'
            name='metallicPaint'
            text={getTranslation('color.metallic.label')}
            checked={props.metallicPaint ?? false}
            handleChange={props.handleCheckboxChange}
          />
        </div>
        <div className='d-flex flex-wrap'>
          {(props.data?.codeBook ?? []).map(item => {
            const checked = props.colors.indexOf(item!.id) > -1
            return (
              <InputCheckboxColor
                key={`id-${item!.id}`}
                name={
                  item!.value ? capitalize(item!.value) : getTranslation('color.undefined.label')
                }
                checked={checked}
                handleClick={newChecked =>
                  props.handleCustomCheckboxChange('colors', item!.id, newChecked)
                }
                // @ts-expect-error
                hex={colorHex[item.id as keyof typeof colorHex] ?? 'transparent'}
                className='col'
              />
            )
          })}
        </div>
      </div>
      <hr className='saps-hr saps-mb-30' />
    </div>
  )
}

export default createFragmentContainer(React.memo(ColorsFilter), {
  data: graphql`
    fragment ColorsFilter_data on Query @argumentDefinitions(lang: { type: "Lang!" }) {
      codeBook(codeBookName: COLOR) {
        id
        value(lang: $lang)
      }
    }
  `,
})
