import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Financing extends ContentItem {
  public financingText: Elements.RichTextElement
  public financingPicture: Elements.AssetsElement
  public actionOffersTabname: Elements.RichTextElement
  public actionOffersText: Elements.RichTextElement
  public akceVwfs: Elements.RichTextElement
  public actionOffersAdvantages: Elements.RichTextElement
  public actionOffersItems: Elements.RichTextElement
  public actionOffersAdvantages2: Elements.RichTextElement
  public additionalInsuranceTabname: Elements.RichTextElement
  public additionalInsuranceText: Elements.RichTextElement
  public aboutServicesTabname: Elements.RichTextElement
  public aboutServicesText: Elements.RichTextElement
  public partners: Elements.LinkedItemsElement<ContentItem>
  public indicativeOfferText: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'financing_text') {
          return 'financingText'
        }
        if (elementName === 'financing_picture') {
          return 'financingPicture'
        }
        if (elementName === 'action_offers_tabname') {
          return 'actionOffersTabname'
        }
        if (elementName === 'action_offers_text') {
          return 'actionOffersText'
        }
        if (elementName === 'akce_vwfs') {
          return 'akceVwfs'
        }
        if (elementName === 'action_offers_advantages') {
          return 'actionOffersAdvantages'
        }
        if (elementName === 'action_offers_items') {
          return 'actionOffersItems'
        }
        if (elementName === 'action_offers_advantages_2') {
          return 'actionOffersAdvantages2'
        }
        if (elementName === 'additional_insurance_tabname') {
          return 'additionalInsuranceTabname'
        }
        if (elementName === 'additional_insurance_text') {
          return 'additionalInsuranceText'
        }
        if (elementName === 'about_services_tabname') {
          return 'aboutServicesTabname'
        }
        if (elementName === 'about_services_text') {
          return 'aboutServicesText'
        }
        if (elementName === 'indicative_offer_text') {
          return 'indicativeOfferText'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
