// TODO: do i need polyfill???
// import 'intersection-observer'
import { getTranslation } from './languageUtils'
import { process_browser } from '../../components/withNoSSR'

// //////////////////////////////////////
// //////////////////////////////////////
// //////////////////////////////////////
// TODO: implement custom flat polyfill
// We should use babel polyfill instead of custom
// > https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
// We think that flat polyfill is not needed now
/*
if (!Array.prototype.flat) {
  Array.prototype.flat = function() {
    // eslint-disable-next-line prefer-rest-params
    let depth = arguments[0]
    depth = depth === undefined ? 1 : Math.floor(depth)
    if (depth < 1) return Array.prototype.slice.call(this)
    return (function flat(arr, depth) {
      // eslint-disable-next-line no-bitwise
      const len = arr.length >>> 0
      let flattened = []
      let i = 0
      while (i < len) {
        if (i in arr) {
          const el = arr[i]
          if (Array.isArray(el) && depth > 0) {
            flattened = flattened.concat(flat(el, depth - 1))
          } else {
            flattened.push(el)
          }
        }
        i++
      }
      return flattened
    })(this, depth)
  }
}
*/

// //////////////////////////////////////
// //////////////////////////////////////
// //////////////////////////////////////

export const buildUrl = (page: string, params: [string, any][]) => {
  if (!process_browser) {
    return ''
  }
  const urlBase = window.location.origin
  const urlRelative = page
  const url = new URL(urlRelative, urlBase)

  params.forEach(([urlKey, urlValue]) => {
    if (urlKey && urlValue) {
      url.searchParams.append(urlKey, urlValue)
    }
  })

  return url
}

/*
 * Division function that always return integer by adding modulo to base which of course
 * will modify the final result
 */
export const noModulo = (x: number, y: number) => {
  if (x === 0) {
    return 0
  }

  const modulo = x % y

  return modulo === 0 ? x / y + 1 : (x - modulo) / y
}

export const genArrBySize = (size: number) => Array.from({ length: size }).fill(0) as number[]

export const isNumeric = (n: string | number) => {
  return !isNaN(parseFloat(n as string)) && isFinite(n as any as number)
}

export const capitalize = (s: boolean | string | null | undefined) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const parseModelLevel = (modelType: null | string | undefined) => {
  const modelTypeDefaultValues = { modelLevel: null, equipment: null }

  if (modelType === null || modelType === undefined) {
    return modelTypeDefaultValues
  }

  const patternLast2Worlds = /^.*\s(\w+)\s(\w+|L&K)$/
  const modelLevel = [] as string[]
  let equipment

  const match = modelType.match(patternLast2Worlds)

  if (match === null) {
    equipment = modelType

    return { modelLevel, equipment }
  } else {
    if (match[1].length > 3 && match[2].length > 3) {
      modelLevel.push(`${match[1]} ${match[2]}`)
    } else {
      modelLevel.push(match[2])
    }

    const dsg = 'DSG'
    const fourXFour = '4x4'
    const combi = 'Combi'
    const transmission_auto = match[0].includes(dsg)
    const four_four = match[0].includes(fourXFour)
    const pipe = '\u00a0\u00a0<span class="saps-equipment-pipe">|</span>\u00a0\u00a0'

    equipment =
      match[0]
        // @ts-expect-error
        .replace(modelLevel, '')
        .replace(dsg, '')
        .replace(fourXFour, '')
        .replace(combi, '')
        .replace(/\//g, pipe) +
      pipe +
      (transmission_auto
        ? getTranslation('transmission.automatic')
        : getTranslation('transmission.manual')) +
      pipe +
      (four_four ? '4x4' : '4x2')

    return { modelLevel, equipment }
  }
}

export const kwToHp = (kw: number) => kw * 1.34102209

export const litresFromCm3 = (volumeInCm3: number, separator = ',') => {
  if (volumeInCm3 && !isNaN(volumeInCm3)) {
    return (Math.round(volumeInCm3 / 100) / 10).toFixed(1).toString().replace('.', separator)
  }

  return 0
}

export const removeFromArray = <T>(array: T[], itemToRemove: T) => {
  const index = array.indexOf(itemToRemove)
  if (index > -1) {
    array.splice(index, 1)
  }

  return array
}

export const getPaintTypeTranslation = (paintType: string) => {
  switch (paintType) {
    case 'PEARL':
      return getTranslation('paintType.pearl')
    case 'METALLIC':
      return getTranslation('paintType.metallic')
    case 'BASIC':
      return getTranslation('paintType.basic')
    default:
      return paintType
  }
}

export const isNilOrEmpty = <T>(val: null | undefined | '' | T) => {
  const hasValue = val !== '' && val !== undefined && val !== null
  return !hasValue
}

export const findNumberInString = (string: string) => {
  if (string) {
    const numRegExp = /\d{1,}/
    const matchList = string.toString().match(numRegExp)

    return matchList ? matchList[0] : ''
  }

  return ''
}

/**
 * global ids in applications are created by:
 * {dateType}-{id}
 *
 * for example:
 *  - Car-1
 *  - Dealer-4
 *  - Car-1335
 *  etc...
 */
export const parseGlobalId = (globalId = '') => {
  const parsedByDash = globalId.split('-')
  return parsedByDash[0] && parsedByDash[1]
    ? {
        type: parsedByDash[0],
        originalId: parsedByDash[1],
      }
    : null
}

export const filterOutEmptyObjValues = <T extends Record<any, any>>(obj: T) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => !(Array.isArray(v) && v.length === 0)))

export const getClickableLink = (link: string) => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`
}

export type DatalayerPlaceholders = {
  market: {
    Placeholder1?: null | string
    Placeholder2?: null | string
  }
}

export const getPlaceholdersFromLS = (): DatalayerPlaceholders => {
  const placeholders = localStorage.getItem('placeholders')
  if (!placeholders) {
    return {
      market: {},
    }
  }
  savePlaceholdersToLS({
    market: {},
  })
  return JSON.parse(placeholders) as DatalayerPlaceholders
}

export const savePlaceholdersToLS = (placeholders: DatalayerPlaceholders) => {
  localStorage.setItem('placeholder', JSON.stringify(placeholders))
}

export const cacheDealersToFilter = (
  dealers: { id: string; dealerMicrositeId: string }[] | null | undefined
) => {
  if (typeof window !== 'undefined') {
    if (!dealers || dealers.length === 0) {
      return
    }
    localStorage.setItem('cachedDealers', JSON.stringify(dealers))
  }
}

export const getCachesDealersToFilter = (): { id: string; dealerMicrositeId: string }[] | null => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const dealerString = localStorage.getItem('cachedDealers')
    return dealerString
      ? (JSON.parse(dealerString) as { id: string; dealerMicrositeId: string }[])
      : null
  }
  return null
}
