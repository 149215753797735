import React from 'react'

type Props = {
  children?: React.ReactChild
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  fullWidth?: boolean
  greyButton?: boolean
  disabled?: boolean
  orangeText?: boolean
  className?: string
  style?: React.CSSProperties
}
const Button = (props: Props) => {
  let buttonStyleClassNames = 'saps-button '
  if (props.greyButton) {
    buttonStyleClassNames = buttonStyleClassNames.concat('saps-button-grey-6--outline ')
  } else {
    buttonStyleClassNames = buttonStyleClassNames.concat('saps-button--secondary ')
  }

  if (props.fullWidth) {
    buttonStyleClassNames = buttonStyleClassNames.concat('saps-button--fullwidth ')
  }

  return (
    <button
      type='button'
      disabled={props.disabled}
      onClick={props.onClick}
      className={`${props.className || ''} ${buttonStyleClassNames || ''}`}
      style={props.style}
    >
      {props.children}
    </button>
  )
}

export default Button
