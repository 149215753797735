import { getTranslation } from '../../utils/languageUtils'
import React, { useEffect, useMemo, useRef } from 'react'

type Props = {
  videoUrl: string
  nested?: boolean
  handleClose: () => void
}
const VideoLightbox = ({ videoUrl, nested = false, handleClose }: Props) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null)

  const handleClickAnywhere = (e: MouseEvent) => {
    // close lightBox if clicked outside of it
    if (!wrapperRef.current?.contains?.(e.target as Node)) {
      handleClose()
    }
  }

  useEffect(() => {
    if (nested === false) {
      document.querySelector('body')!.style.overflowY = 'hidden'
      return () => {
        document.querySelector('body')!.style.overflowY = ''
      }
    }
    return
  })

  useEffect(() => {
    document.addEventListener('click', handleClickAnywhere)
    return () => {
      document.removeEventListener('click', handleClickAnywhere)
    }
  })

  const isLinkYoutube = useMemo(() => {
    // REGEX IS YOUTUBE LINK

    return /(?:.+?)?(?:\/v\/|watch\/|embed\/|\?v=|&v=|youtu\.be\/|\/v=|^youtu\.be\/|watch%3Fv%3D)([a-zA-Z0-9_-]{11})+/.test(
      videoUrl
    )
  }, [videoUrl])

  return (
    <div className='saps-lightbox-wrapper saps-lightbox-wrapper--active'>
      <div
        className='saps-lightbox saps-outer-close'
        ref={wrapperRef}
        style={{
          background: 'none',
          justifyContent: 'center',
          width: '100%',
          height: 'calc(100vw / 2.2222)',
        }}
      >
        <a className='saps-lightbox__close' onClick={handleClose} />
        {isLinkYoutube ? (
          <iframe
            width='100%'
            height='100%'
            src={videoUrl}
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        ) : (
          <video className='video-background' controls autoPlay loop muted playsInline width='100%'>
            <source src={videoUrl} type='video/mp4' />
            <div>{getTranslation('general.video.not.supported')}</div>
          </video>
        )}
      </div>
    </div>
  )
}

export default VideoLightbox
