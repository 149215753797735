import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Homepage extends ContentItem {
  public topBannerText: Elements.RichTextElement
  public topBannerPicture: Elements.AssetsElement
  public topBannerButtonText: Elements.RichTextElement
  public topBannerButtonUrl: Elements.TextElement
  public skodaplusTitle: Elements.RichTextElement
  public skodaplusText: Elements.RichTextElement
  public skodaplusAdvantages: Elements.RichTextElement
  public recommendationTitle: Elements.RichTextElement
  public bottomBannerBanners: Elements.RichTextElement
  public bottomBannerText: Elements.RichTextElement
  public bottomBannerTextMobile: Elements.RichTextElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  public objectType: Elements.TaxonomyElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'top_banner_text') {
          return 'topBannerText'
        }
        if (elementName === 'top_banner_picture') {
          return 'topBannerPicture'
        }
        if (elementName === 'top_banner_button_text') {
          return 'topBannerButtonText'
        }
        if (elementName === 'top_banner_button_url') {
          return 'topBannerButtonUrl'
        }
        if (elementName === 'skodaplus_title') {
          return 'skodaplusTitle'
        }
        if (elementName === 'skodaplus_text') {
          return 'skodaplusText'
        }
        if (elementName === 'skodaplus_advantages') {
          return 'skodaplusAdvantages'
        }
        if (elementName === 'recommendation_title') {
          return 'recommendationTitle'
        }
        if (elementName === 'bottom_banner_banners') {
          return 'bottomBannerBanners'
        }
        if (elementName === 'bottom_banner_text') {
          return 'bottomBannerText'
        }
        if (elementName === 'bottom_banner_text_mobile') {
          return 'bottomBannerTextMobile'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        return elementName
      },
    })
  }
}
