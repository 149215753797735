import { ContentItem, Elements } from '@kentico/kontent-delivery'

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class TriedCars extends ContentItem {
  public triedCarsText: Elements.RichTextElement
  public triedCarsPicture: Elements.AssetsElement
  public servicesTitle: Elements.RichTextElement
  public servicesItems: Elements.RichTextElement
  public servicesLink: Elements.RichTextElement
  public advantagesAndFinancing: Elements.LinkedItemsElement<ContentItem>
  public partners: Elements.LinkedItemsElement<ContentItem>
  public objectType: Elements.TaxonomyElement
  public pageParentUrl: Elements.TextElement
  public pageParentName: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'tried_cars_text') {
          return 'triedCarsText'
        }
        if (elementName === 'tried_cars_picture') {
          return 'triedCarsPicture'
        }
        if (elementName === 'services_title') {
          return 'servicesTitle'
        }
        if (elementName === 'services_items') {
          return 'servicesItems'
        }
        if (elementName === 'services_link') {
          return 'servicesLink'
        }
        if (elementName === 'advantages_and_financing') {
          return 'advantagesAndFinancing'
        }
        if (elementName === 'object_type') {
          return 'objectType'
        }
        if (elementName === 'page_parent__url') {
          return 'pageParentUrl'
        }
        if (elementName === 'page_parent__name') {
          return 'pageParentName'
        }
        return elementName
      },
    })
  }
}
